<template>
  <div class="event-listing scroll-area" v-if="events.length > 0">
    <div class="inner-scroll-area" @scroll="onScroll">
      <div class="events-container">
        <EventCard
          v-for="event in events"
          :key="event.id"
          :event="event"
          :hideCategory="hideCategory"
          :typeSelection="typeSelection"
          :linkPath="linkPath"
        />
      </div>
    </div>

    <div class="btn-container" :class="{scrolled: isScrolled}">
      <div class="scroll-btn">
        <i class="icon icon-scroll-arrow"></i>
        <i class="icon icon-scroll-hand"></i>
      </div>
      <slot name="filter-btn"></slot>
    </div>
  </div>

  <div v-else class="no-result">
    <p>{{$t('agenda.no-results')}}</p>

    <slot name="filter-btn"></slot>
  </div>
</template>

<script>
import EventCard from '@/components/cards/EventCard';
import utils from '@/utils';

export default {
  components: {
    EventCard,
  },
  data() {
    return {
      scrollAmount: 0,
    };
  },
  props: {
    events: Array,
    hideCategory: {
      type: Boolean,
      default: false,
    },
    typeSelection: {
      type: String,
      required: true,
    },
    linkPath: {
      type: String,
      required: true,
    },
  },
  computed: {
    noScroll() {
      return this.events.length <= 9;
    },
    isScrolled() {
      return this.scrollAmount > 500 || this.noScroll;
    },
  },
  methods: {
    onScroll(event) {
      this.scrollAmount = event.currentTarget.scrollTop;
    },
  },
  mounted() {
    utils.wait(500)
      .then(() => this.$root.$emit('fully-loaded', this.$route.path));
  },
};
</script>

<style lang="scss" scoped>
.events-container {
  display: grid;
  grid-auto-rows: max-content;
  grid-gap: 40px;
  grid-template-columns: repeat(3, 1fr);
  min-height: 100%;
  overflow: auto;
}

.btn-container {
  position: absolute;
  z-index: 10;
  right: 0;
  bottom: 550px;
  left: 0;
  text-align: center;

  &.scrolled {
    .scroll-btn {
      transform: translate(-50%, -100px);
      opacity: 0;
      pointer-events: none;
    }
  }

  &:not(.scrolled) {
    .btn {
      transform: translateY(100px);
      opacity: 0;
      pointer-events: none;
    }
  }

  .btn {
    transition: opacity $transition-duration ease, transform $transition-duration ease;
    box-shadow: 0 4px 34px rgba($true-black, 0.25);
  }
}

.scroll-btn {
  position: absolute;
  z-index: 0;
  top: 0;
  left: 50%;
  width: 300px;
  height: 300px;
  transform: translateX(-50%);
  transition: opacity $transition-duration ease, transform $transition-duration ease;
  border-radius: 50%;
  background-color: $white;
  box-shadow: 0 4px 34px rgba($true-black, 0.15);

  .icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: $pink;
    font-size: 13rem;
  }

  .icon-scroll-hand {
    animation: scroll-animate 1.6s infinite ease;
  }

  @keyframes scroll-animate {
    0%,
    100% {
      transform: translate(-50%, -60%);
    }

    30% {
      transform: translate(-50%, -10%);
    }
  }
}

.scroll-area {
  &::after {
    content: '';
    display: block;
    position: absolute;
    z-index: 5;
    right: 0;
    bottom: -1px;
    left: 0;
    height: 800px;
    background: linear-gradient(to top, $white-alt 0%, rgba($white-alt, 0) 100%);
    pointer-events: none;
  }

  .inner-scroll-area {
    padding-bottom: 800px;
  }
}
</style>
