<template>
  <div class="standby">
    <div class="background" v-if="backgroundImage">
      <img :src="backgroundImage" alt="">
    </div>

    <div class="modal" v-if="type === 'image-texte'">
      <p class="texte" v-html="display.textes.fr"></p>
      <p class="texte" v-html="display.textes.en"></p>
      <p class="texte" v-html="display.textes.es"></p>
    </div>

    <div class="video" v-if="type === 'video'">
      <video autoplay loop muted>
        <source :src="display.video" type="video/mp4">
      </video>
    </div>

    <div class="outro" v-if="!backgroundImage">
      <p class="hashtag">#VisitezToulouse</p>
      <p class="website">www.toulouse-tourisme.com</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'standby',
  props: {
    display: {
      type: Object,
      required: true,
    },
  },
  computed: {
    type() {
      return this.display.type_daffichage;
    },
    backgroundImage() {
      return this.display.image_totem;
    },
  },
};
</script>

<style scoped lang="scss">
$lateral-gap: 160px;
$modal-radius: 80px;
$modal-padding: 200px 150px;
$modal-font-size: 9rem;
$red-error: #E83E31;

.standby {
  display: flex;
  position: fixed;
  z-index: 100;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: $lateral-gap;
  background: $gradient-toulouse;

  &::before {
    content: '';
    display: block;
    position: absolute;
    z-index: -2;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0.05;
    background-image: url(~@/assets/images/bg-map.jpg);
  }

  .background {
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;

    img {
      display: block;
      width: 100%;
    }
  }

  .modal {
    width: 100%;
    padding: $modal-padding;
    border-radius: $modal-radius;
    background-color: $white;

    .texte {
      margin-bottom: 0;
      color: $red-error;
      font-size: $modal-font-size;
      font-weight: $light-weight;
      line-height: 1.5;
      text-align: center;

      &:not(:last-child) {
        position: relative;
        margin-bottom: 70px;
        padding-bottom: 70px;

        &::after {
          content: '';
          display: block;
          position: absolute;
          bottom: 0;
          left: 50%;
          width: 90px;
          transform: translateX(-50%);
          border-top: 2px solid $black;
        }
      }
    }
  }

  .video {
    position: absolute;
    top: 50%;
    right: $lateral-gap;
    left: $lateral-gap;
    transform: translateY(-50%);

    video {
      width: 100%;
      height: auto;
    }
  }

  .outro {
    position: relative;
    top: auto;
    right: auto;
    bottom: auto;
    left: auto;
    margin-top: 140px;

    .hashtag {
      font-size: 11.5rem;
    }
  }
}
</style>
