<template>
  <div class="view home" :class="{ a11y: isA11yActivate }">
    <div class="page-intro">
      <img class="logo" alt="Logo Toulouse" src="../assets/images/logo-toulouse-white.svg">
      <Meteo />
    </div>

    <h1 class="main-title" v-html="$t('welcome')"></h1>

    <div class="home-nav">
      <router-link to="/decouvrir" class="home-link decouvrir">
        <HomeAnimation
          id="decouvrir"
          :size="661"
          ref="video-0"
        />
        <span class="label" v-html="$t('menu.decouvrir')"></span>
      </router-link>
      <router-link to="/agenda" class="home-link agenda">
        <HomeAnimation
          id="agenda"
          :size="570"
          ref="video-1"
        />
        <span class="label" v-html="$t('menu.agenda')"></span>
      </router-link>
      <router-link to="/activites" class="home-link activites">
        <HomeAnimation
          id="activites"
          :size="552"
          ref="video-2"
        />
        <span class="label" v-html="$t('menu.activites')"></span>
      </router-link>
      <router-link to="/gourmande" class="home-link gourmande">
        <HomeAnimation
          id="gourmande"
          :size="546"
          ref="video-3"
        />
        <span class="label" v-html="$t('menu.gourmande')"></span>
      </router-link>
      <router-link to="/region" class="home-link region">
        <HomeAnimation
          id="region"
          :size="409"
          ref="video-4"
        />
        <span class="label" v-html="$t('menu.region')"></span>
      </router-link>
    </div>
    <UtilButtons onHome="true" />

    <div class="outro">
      <p class="hashtag">#VisitezToulouse</p>
      <p class="website">www.toulouse-tourisme.com</p>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import UtilButtons from '@/components/UtilButtons.vue';
import Meteo from '@/components/Meteo.vue';
import HomeAnimation from '@/components/HomeAnimation.vue';

export default {
  name: 'home',
  components: {
    UtilButtons,
    Meteo,
    HomeAnimation,
  },
  computed: {
    ...mapState(['isA11yActivate']),
  },
  methods: {
    playVideo(index) {
      this.$refs[`video-${index}`].play();
    },
    pauseVideo(index) {
      this.$refs[`video-${index}`].pause();
    },
  },
  activated() {
    this.playVideo(0);
    this.playVideo(1);
    this.playVideo(2);
    this.playVideo(3);
    this.playVideo(4);
  },
  deactivated() {
    this.pauseVideo(0);
    this.pauseVideo(1);
    this.pauseVideo(2);
    this.pauseVideo(3);
    this.pauseVideo(4);
  },
};
</script>

<style scoped lang="scss">
.home {
  padding: $page-padding;
  color: $white;

  &::before {
    content: '';
    display: block;
    position: absolute;
    z-index: 0;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0.05;
    background-image: url(~@/assets/images/bg-map.jpg);
  }

  &:not(.a11y) {
    .home-link {
      position: absolute;

      &.decouvrir {
        top: 0;
        left: 160px;
      }

      &.gourmande {
        right: 60px;
        bottom: 0;
      }

      &.activites {
        top: 830px;
        left: 650px;
      }

      &.region {
        bottom: 0;
        left: 140px;
      }

      &.agenda {
        top: 230px;
        right: 30px;
      }
    }
  }

  &.a11y {
    .outro {
      display: none;
    }

    .home-nav {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      justify-content: center;
      height: auto;
      padding-right: 0;
    }

    .home-link {
      flex: 0 0 auto;
      width: 600px;
      margin-bottom: 90px;

      .home-animation {
        width: 360px;
      }
    }

    .util-buttons {
      bottom: 200px;
    }
  }
}

.home-nav {
  position: relative;
  width: 95%;
  height: 2100px;
  margin-bottom: $spacing-alt;
}

.main-title {
  font-size: 18.5rem;
}

.meteo {
  color: $white;
}

.home-link {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: $white;
  text-decoration: none;

  img {
    width: 360px;
    height: auto;
  }

  .label {
    margin-top: $spacing-xsmall;
    margin-bottom: 0;
    font-size: 7rem;
    font-weight: $bold-weight;
    line-height: 1.3;
    text-align: center;
  }
}

.outro {
  position: absolute;
  z-index: 100;
  right: 500px;
  bottom: 280px;
  left: 500px;
}
</style>
