<template>
  <Modal :visible="visible" ref="modal" @close="emitNewValues">
    <template v-slot:title>
      <p class="modal-label"><i class="icon icon-filter"></i> {{$t('agenda.filter-title')}}</p>
    </template>

    <p class="filter-title">{{$t('agenda.filter.when.label')}}</p>
    <div class="filters">
      <div class="filter" v-for="(label, id) in labels.when" :key="id">
        <input type="radio" name="when" :id="`when-${id}`" v-model="newValues.when" :value="id">
        <label :for="`when-${id}`">
          <span class="label">{{label}}</span>
          <span class="icon icon-cross"></span>
        </label>
      </div>
    </div>

    <p class="filter-title">{{$t('agenda.filter.categories.label')}}</p>
    <div class="filters">
      <div class="filter" v-for="(label, id) in labels.categories" :key="id">
        <input type="checkbox" name="categories" :id="`categories-${id}`" v-model="newValues.categories" :value="id">
        <label :for="`categories-${id}`">
          <span class="label">{{label}}</span>
          <span class="icon icon-cross"></span>
        </label>
      </div>
    </div>

    <p class="filter-title">{{$t('agenda.filter.who.label')}}</p>
    <div class="filters">
      <div class="filter" v-for="(label, id) in labels.who" :key="id">
        <input type="checkbox" name="who" :id="`who-${id}`" v-model="newValues.who" :value="id">
        <label :for="`who-${id}`">
          <span class="label">{{label}}</span>
          <span class="icon icon-cross"></span>
        </label>
      </div>
    </div>

    <div class="btn-container">
      <button class="circle-btn large" @click="onSubmit">
        <i class="icon icon-arrow-right"></i>
      </button>
    </div>
  </Modal>
</template>

<script>
import Modal from '@/components/modals/Modal';

export default {
  data() {
    return {
      visible: false,
      newValues: { ...this.values },
    };
  },
  props: {
    labels: Object,
    // values: Object,
  },
  components: {
    Modal,
  },
  computed: {
  },
  watch: {
    values(newVal) {
      this.newValues = { ...newVal };
    },
  },
  methods: {
    open(filterValues) {
      this.newValues = { ...filterValues };
      this.$refs.modal.open();
    },
    onSubmit() {
      this.$refs.modal.close();
      this.emitNewValues();
    },
    emitNewValues() {
      this.$emit('filterUpdate', this.newValues);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .modal {
  width: 72%;
}

.modal-container {
  padding-bottom: 900px;
}

.modal-label {
  .icon {
    color: $turquoise;
  }
}

.filter-title {
  margin-bottom: 1em;
  font-size: 4rem;
  font-weight: $book-weight;
}

.filters {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: $spacing - 45px;
}

.filter {
  margin-right: 10px;
  margin-bottom: 45px;

  input {
    display: none;

    &:checked {
      ~ label {
        background-color: $turquoise;
        color: $white;
      }

      &[type=checkbox] {
        ~ label {
          background-color: $turquoise;
          color: $white;

          .label {
            transform: translateX(-15px);
          }

          .icon-cross {
            transform: translate(0, -50%);
            opacity: 1;
          }
        }
      }
    }
  }

  label {
    display: block;
    position: relative;
    padding: 30px 60px;
    transition: background-color $transition-duration ease, color $transition-duration ease;
    border-radius: 90px;
    background-color: $white-ter;
    color: $grey;
    line-height: 1.1;
    text-transform: uppercase;

    .label {
      display: inline-block;
      transition: transform $transition-duration ease;
    }

    .icon-cross {
      position: absolute;
      top: 50%;
      right: 30px;
      transform: translate(10px, -50%);
      transition: transform $transition-duration ease, opacity $transition-duration ease;
      opacity: 0;
    }
  }
}

.btn-container {
  text-align: right;
}
</style>
