import utils from '@/utils';
import WordpressAjax from '@/wordpress-ajax';
import {
  UPDATE_LIEUX,
  UPDATE_QUARTIERS,
  UPDATE_QUARTIER_POIS,
  UPDATE_SITES,
  UPDATE_VISITES,
} from '@/store/mutation-types';
// import lieux from '@/assets/tempData/agenda.json';
import i18n from '@/i18n';

export default {
  namespaced: true,
  state: {
    lieux: {},
    sites: {},
    quartiers: {},
    quartiers_pois: {},
  },
  mutations: {
    [UPDATE_LIEUX](state, lieux) {
      state.lieux = lieux;
    },
    [UPDATE_SITES](state, sites) {
      state.sites = sites;
    },
    [UPDATE_VISITES](state, visites) {
      state.visites = visites;
    },
    [UPDATE_QUARTIERS](state, quartiers) {
      state.quartiers = quartiers;
    },
    [UPDATE_QUARTIER_POIS](state, pois) {
      state.quartiers_pois = pois;
    },
  },
  getters: {
    getItemsOfCurrentLang: state => type => utils.getItemsOfCurrentLang(state[type], i18n.locale),
    getItemById: (state, getters) => (id, type) => getters.getItemsOfCurrentLang(type).find(lieu => lieu.id === id),
    categories: (state) => {
      const categories = {};

      state.lieux.forEach((event) => {
        event.categorie.forEach((cat) => {
          categories[utils.generateID(cat)] = cat;
        });
      });

      return categories;
    },
  },
  actions: {
    updateFromAPI(context) {
      return Promise.all([
        WordpressAjax.get('get_lieux'),
        WordpressAjax.get('get_sites_musees_monuments'),
        WordpressAjax.get('get_quartier'),
        WordpressAjax.get('get_poi'),
      ])
        .then((res) => {
          context.commit(UPDATE_LIEUX, res[0]);
          context.commit(UPDATE_SITES, res[1]);
          context.commit(UPDATE_QUARTIERS, res[2]);
          context.commit(UPDATE_QUARTIER_POIS, res[3]);
        });
    },
  },
};
