<template>
  <router-link class="back-link" :to="backTo">
    <div class="back-btn circle-btn">
      <i class="icon icon-arrow-left"></i>
    </div>
    <span>{{$t('back')}}</span>
  </router-link>
</template>

<script>
export default {
  props: {
    backTo: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.back-link {
  display: flex;
  align-items: center;
  margin-bottom: 50px;
  color: $black;
  font-size: 3rem;
  font-weight: $book-weight;
  text-decoration: none;
}

.back-btn {
  margin-right: 20px;
}
</style>
