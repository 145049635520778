<template>
  <div class="page" :class="{ a11y: isA11yActivate }">
    <div class="page-intro">
      <img class="logo" alt="Logo Toulouse" src="../assets/images/logo-toulouse.svg">
      <Meteo />
    </div>

    <h1 class="main-title" v-if="pageTitle">{{$t(`${pageTitle}.title`)}}</h1>

    <div class="page-content">
      <SecondNav />
      <keep-alive>
        <router-view/>
      </keep-alive>
      <slot></slot>
    </div>

    <MainMenu />
    <UtilButtons />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import MainMenu from '@/components/MainMenu.vue';
import Meteo from '@/components/Meteo.vue';
import UtilButtons from '@/components/UtilButtons.vue';
import SecondNav from '@/components/SecondNav.vue';

export default {
  props: {
    title: {},
  },
  computed: {
    ...mapState(['isA11yActivate']),
    pageTitle() {
      if (this.title) {
        return this.title;
      }
      return this.$route.matched[0].meta.title ? this.$route.matched[0].meta.title : false;
    },
  },
  components: {
    MainMenu,
    Meteo,
    UtilButtons,
    SecondNav,
  },
};
</script>

<style lang="scss" scoped>
.page {
  position: absolute;
  top: $global-border-width;
  right: $global-border-width;
  bottom: $global-border-width;
  left: $global-border-width;
  padding: $page-padding;
  overflow: hidden;
  border-radius: $global-radius;
  background-color: $white-alt;

  &.a11y {
    .main-menu {
      top: auto;
      bottom: -1px;
      left: 50%;
    }

    .util-buttons {
      bottom: 50%;
      padding: 180px 0 140px;
      transform: translateY(50%);
    }
  }

  .main-menu {
    position: absolute;
    z-index: 3;
    top: 400px;
    left: -1px;

    &.a11y {
      z-index: 11;
    }
  }

  .util-buttons {
    position: absolute;
    z-index: 2;
    bottom: 600px;
    left: 0;
    transition: bottom $transition-duration ease, transform $transition-duration ease;
  }

  ::v-deep .second-nav {
    margin-bottom: 50px;
  }
}

.main-title {
  position: relative;
  z-index: 4;
  margin-top: $spacing-xsmall-alt;
  margin-bottom: 0;
  color: $true-black;
}

.page-intro {
  position: relative;
  z-index: 4;

  .logo {
    width: 370px !important;
  }
}

.page-content {
  display: flex;
  position: absolute;
  top: 480px;
  right: $page-padding-right;
  bottom: 0;
  left: 450px;
  flex-direction: column;

  .second-nav + * {
    flex: 1 1 auto;
    height: 100%;
    min-height: 0;
  }

  > *:not(.second-nav) {
    height: 100%;
  }
}
</style>
