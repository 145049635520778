<template>
  <PageCards
    :cards="coeurs"
    typeSelection="gourmande/coeurs"
  >
    <template v-slot:deco-top>
      <div class="wrapper-card card-coup-de-coeur">
        <div class="card-container">
          <div class="card-top">
            <img src="@/assets/images/gourmande-team.jpg" class="image" alt="">
            <span class="shape"></span>
          </div>
          <div class="card-content">
            <p class="card-title">{{$t('gourmande.coups-de-coeur.intro.title')}}</p>
            <div class="card-text" v-html="$t('gourmande.coups-de-coeur.intro.text')"></div>

            <i class="icon icon-toque"></i>
          </div>
        </div>
      </div>
    </template>
  </PageCards>
</template>

<script>
import { mapGetters } from 'vuex';
import PageCards from '@/components/pages/PageCards';

export default {
  components: {
    PageCards,
  },
  computed: {
    ...mapGetters('gourmande', ['getItemsOfCurrentLang']),
    coeurs() {
      return this.getItemsOfCurrentLang('coeurs');
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .card-coup-de-coeur {
  margin-bottom: 50px;

  .card-top {
    height: 510px;
  }

  .card-content {
    position: relative;
    padding: 80px 200px 100px 90px;
  }

  .card-title {
    margin-bottom: 20px;
    font-size: 5rem;
    font-weight: $bold-weight;
  }

  .card-text {
    font-size: 3rem;
    font-weight: $book-weight;
  }

  .icon-toque {
    position: absolute;
    top: 50%;
    right: 140px;
    transform: translateY(-50%);
    color: $pink;
    font-size: 9.4rem;
  }
}
</style>
