import Vue from 'vue';
import utils from '@/utils';

const filters = {
  formatDate: (value, format) => utils.formatDate(value, format),
  formatHoraire: value => utils.formatHoraire(value),
  formatAdresse: value => utils.formatAdresse(value),
  formatDayHoraire: value => utils.formatDayHoraire(value),
  nl2br: value => utils.nl2br(value),
  striphtml: (value) => {
    const div = document.createElement('div');
    div.innerHTML = value;
    const text = div.textContent || div.innerText || '';
    return text;
  },
  truncate: (value, maxLength, after = '...') => {
    if (value.length > maxLength) {
      const trimmedString = value.substr(0, maxLength);
      // re-trim if we are in the middle of a word and
      return trimmedString.substr(0, Math.min(trimmedString.length, trimmedString.lastIndexOf(' '))) + after;
    }

    return value;
  },
  address: value => utils.getAdresse(value),
};

Object.keys(filters).forEach((filterName) => {
  Vue.filter(filterName, filters[filterName]);
});

export default filters;
