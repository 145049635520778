const debug = process.env.NODE_ENV !== 'production';

const popupShowDelayDefault = 30;
const popupValidateDelayDefault = 30;
const enableTimerDefault = true;
const enablePagesPreloadDefault = true;

/* eslint-disable no-unneeded-ternary */
export default {
  API_URL: debug ? 'https://donjon.w2p.io' : 'https://vps776117.ovh',
  // API_URL: 'https://donjon.w2p.io',
  API_AJAX_PATH: 'wp-admin/admin-ajax.php',
  MAPBOX: {
    ACCESS_TOKEN: 'pk.eyJ1IjoidzJwZGlnaXRhbCIsImEiOiJjajhvcDQ4bnEwNjBpMzJxdTg4Y3BzYWp5In0.0JSlXwIFZ8XvRKJxM41M_g',
    MAP_STYLE: 'mapbox://styles/w2pdigital/ck4y0m43q0kvb1cmtxas08y99?optimize=true',
    // MAP_STYLE: 'mapbox://styles/mapbox/streets-v11',
  },
  googleAnalytics: {
    trackingID: 'UA-157717541-2',
  },
  popupShowDelay: debug ? 5 : popupShowDelayDefault,
  popupValidateDelay: debug ? 5 : popupValidateDelayDefault,
  enableTimer: debug ? false : enableTimerDefault,
  enablePagesPreload: debug ? false : enablePagesPreloadDefault,
};
