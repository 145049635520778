<template>
  <div class="quartiers">
    <div class="intro">
      <p class="map-title" v-if="!currentQuartier">{{$t('decouvrir.quartiers.select')}}</p>
      <template v-else>
        <div class="quartier-intro">
          <p class="map-title">{{currentQuartier.titre}}</p>
          <div class="quartier-selection" @click="addPoisToMySelection">
            <i class="icon icon-heart-outline"></i>
            {{$t('decouvrir.quartiers.add-all-to-selection')}}
          </div>
        </div>

        <div class="map-description" v-html="currentQuartier.description"></div>

        <button class="btn pink back-btn" @click="backToAll" v-show="currentQuartier">
          <i class="icon icon-arrow-left"></i>
          {{$t('decouvrir.quartiers.back-to-all')}}
        </button>
      </template>
    </div>

    <div class="map-container">
      <MapControls
        @zoomIn="zoomIn"
        @zoomOut="zoomOut"
        @recenter="recenter"
      />

      <MglMap
        :accessToken="accessToken"
        :mapStyle="mapStyle"
        :center="center"
        :zoom="zoom"
        :refreshExpiredTiles="false"
        :minZoom="minZoom"
        :maxZoom="maxZoom"
        @load="onMapLoad"
        @zoomstart="handleMapZoomStart"
        @zoom="handleMapZoom"
        @zoomend="handleMapZoomEnd"
        @movestart="handleMapMoveStart"
        @moveend="handleMapMoveEnd"
        @click="onMapClick"
        ref="map"
      >
        <template
          v-for="(quartier, index) in computedQuartiers"
        >
          <MglMarker
            :key="`marker-${quartier.id}`"
            :coordinates="quartier.center.toArray()"
            anchor="center"
          >
            <div
              slot="marker"
              class="quartier-marker"
              v-show="!currentQuartier"
            >
              <div class="count">
                <i class="icon icon-map-pin" :style="{ color: quartier.couleur }"></i>
                <span class="count-label">{{quartier.pois.length}}</span>
              </div>
              <span class="label" :style="{ color: quartier.couleur }">{{quartier.titre}}</span>
            </div>
          </MglMarker>

          <MglGeojsonLayer
            :key="`area-${quartier.id}`"
            :sourceId="`quartier-${index}`"
            :layerId="`quartier-${index}`"
            :source="{
              type: 'geojson',
              data: {
                id: `quartier-${index}`,
                ...quartier.zone,
              },
            }"
            :layer="{
              type: 'fill',
              paint: {
                'fill-color': quartier.couleur,
                'fill-opacity': isQuartierVisible(quartier.id) ? 0.25 : 0.1
              },
            }"
            @click="showQuartier(quartier)"
          />

          <ClusteredMarkers
            :key="`markers-${quartier.id}`"
            :ref="`markers-${quartier.id}`"
            :locations="quartier.pois"
            :zoom="currentZoom"
            @clusterClick="onClusterClick"
            @markerClick="onMarkerClick"
            :visible="showMarkers && isQuartierVisible(quartier.id)"
          />
        </template>
      </MglMap>

      <MapPopupCard
        v-if="currentLocation"
        :key="currentLocation.id"
        :imageUrl="currentLocation.image && currentLocation.image.small ? currentLocation.image.small : false"
        :title="currentLocation.titre"
        :description="getDescription(currentLocation)"
        :typeSelection="typeSelection"
        :itemSelection="currentLocation"
        @close="hidePopin"
      />
    </div>

    <LocationCarousel
      key="carousel-quartiers"
      v-show="!currentQuartier"
    >
      <div
        class="swiper-slide"
        v-for="(quartier, index) in computedQuartiers"
        :key="index"
      >
        <QuartierCard
          :image="quartier.image && quartier.image.small ? quartier.image.small : undefined"
          :title="quartier.titre"
          :color="quartier.couleur"
          :count="quartier.pois.length"
          @cardClick="showQuartier(quartier)"
        />
      </div>
    </LocationCarousel>

    <LocationCarousel
      v-if="currentQuartier"
    >
      <div
        class="swiper-slide"
        v-for="(location, index) in currentQuartier.pois"
        :key="index"
      >
        <LocationCard
          :imageUrl="location.image && location.image.small ? location.image.small : false"
          :title="location.titre"
          :typeSelection="typeSelection"
          :itemSelection="location"
          @cardClick="showOnMap(currentQuartier.id, location.id)"
        />
      </div>
    </LocationCarousel>
  </div>
</template>

<script>
import * as turf from '@turf/turf';
import { mapGetters, mapState, mapMutations } from 'vuex';
import Mapbox from 'mapbox-gl';
import { MglMap, MglMarker, MglGeojsonLayer } from 'vue-mapbox';
import { ADD_ITEM, REMOVE_ITEM } from '@/store/mutation-types';
import config from '@/config';
import utils from '@/utils';
import mapUtils from '@/mapUtils';
import ClusteredMarkers from '@/components/ClusteredMarkers';
import LocationCarousel from '@/components/LocationCarousel';
import MapControls from '@/components/MapControls';
import QuartierCard from '@/components/cards/QuartierCard';
import LocationCard from '@/components/cards/LocationCard';
import MapPopupCard from '@/components/cards/MapPopupCard';

export default {
  components: {
    MglMap,
    MglMarker,
    MglGeojsonLayer,
    MapControls,
    QuartierCard,
    LocationCard,
    MapPopupCard,
    ClusteredMarkers,
    LocationCarousel,
  },
  data() {
    return {
      accessToken: config.MAPBOX.ACCESS_TOKEN, // your access token. Needed if you using Mapbox maps
      mapStyle: config.MAPBOX.MAP_STYLE, // your map style
      currentQuartier: null,
      center: mapUtils.defaultCenter,
      zoom: mapUtils.defaultZoom,
      minZoom: mapUtils.minZoom,
      maxZoom: mapUtils.maxZoom,
      quartierPadding: {
        ...mapUtils.mapPadding,
        top: 320,
        bottom: 100,
      },
      quartierPaddingA11y: {
        ...mapUtils.mapPaddingA11y,
        top: 95,
        bottom: 80,
      },
      mapMoving: false,
      currentZoom: false,
      showMarkers: false,
      selectedLocationId: false,
      typeSelection: 'decouvrir/quartiers_pois',
      isActivated: false,
    };
  },
  computed: {
    ...mapState(['isA11yActivate']),
    ...mapState('decouvrir', ['quartiers']),
    ...mapGetters('decouvrir', ['getItemsOfCurrentLang']),
    mapPadding() {
      let padding = this.isA11yActivate ? mapUtils.mapPaddingA11y : mapUtils.mapPadding;

      if (this.currentQuartier) {
        padding = this.isA11yActivate ? this.quartierPaddingA11y : this.quartierPadding;
      }

      return padding;
    },
    pois() {
      return this.getItemsOfCurrentLang('quartiers_pois');
    },
    computedQuartiers() {
      return this.quartiers.map((quartier) => {
        const bounds = this.getQuartierBounds(quartier);
        const center = this.getBoundsCenter(bounds);

        return {
          ...quartier,
          bounds,
          center,
          pois: this.poiByQuartier(quartier),
        };
      });
    },
    maxBounds() {
      const bounds = new Mapbox.LngLatBounds();

      this.quartiers.forEach((quartier) => {
        utils.extendBounds(bounds, quartier.zone.features[0].geometry.coordinates[0]);
      });

      return bounds;
    },
    currentLocation() {
      return this.pois.find(location => location.id === this.selectedLocationId);
    },
  },
  methods: {
    onMapLoad({ map, component }) {
      this.mapAsyncActions = component.actions;

      map.resize();
      map.dragRotate.disable();
      map.touchZoomRotate.disableRotation();

      this.currentZoom = map.getZoom();

      this.recenter();

      utils.wait(300)
        .then(() => this.$root.$emit('fully-loaded', this.$route.path));
    },
    zoomIn() {
      if (this.mapMoving) {
        return;
      }

      this.mapAsyncActions.zoomIn();
    },
    zoomOut() {
      if (this.mapMoving) {
        return;
      }

      this.mapAsyncActions.zoomOut();
    },
    recenter() {
      let bounds = this.maxBounds;

      if (this.currentQuartier) {
        bounds = this.currentQuartier.bounds;
      }

      return this.fitMapToBounds(bounds, this.mapPadding);
    },
    handleMapMoveStart() {
      this.hidePopin();
    },
    handleMapMoveEnd({ map }) {
      if (!this.isActivated || this.mapMoving) {
        return;
      }

      let bounds = this.maxBounds;

      if (this.currentQuartier) {
        bounds = this.currentQuartier.bounds;
      }

      const newBounds = mapUtils.areBoundsInBounds(bounds, map.getBounds());

      if (!this.outsideCentering && newBounds) {
        this.outsideCentering = true;

        this.fitMapToBounds(newBounds)
          .then(() => {
            this.outsideCentering = false;
          });
      }
    },
    handleMapZoomStart() {
      this.mapMoving = true;
      this.hidePopin();
    },
    handleMapZoomEnd() {
      this.mapMoving = false;
    },
    handleMapZoom({ map }) {
      const newZoom = Math.round(map.getZoom() * 100) / 100;

      if (newZoom !== this.currentZoom) {
        this.currentZoom = newZoom;
      }
    },
    onMapClick() {
      this.hidePopin();
    },
    onClusterClick(clusterBounds) {
      return this.fitMapToBounds(clusterBounds);
    },
    onMarkerClick(markerId) {
      this.selectMarker(markerId);
    },
    showPopin(spotId) {
      if (this.selectedLocationId === spotId) {
        return;
      }

      this.selectedLocationId = spotId;
    },
    showOnMap(quartierId, spotId) {
      if (this.currentLocation && this.currentLocation.id === spotId) {
        return;
      }

      const clusteredMarkers = this.$refs[`markers-${quartierId}`][0];
      const isClustered = clusteredMarkers.isClustered(spotId);

      if (!isClustered) {
        this.selectMarker(spotId);
      } else {
        this.selectMarker(spotId, clusteredMarkers.maxZoom);
      }
    },
    hidePopin() {
      this.selectedLocationId = null;
    },
    selectMarker(spotId, zoom = false) {
      if (this.currentLocation && this.currentLocation.id === spotId) {
        return;
      }

      this.hidePopin();
      this.mapMoving = true;

      const location = this.pois.find(loc => loc.id === spotId);

      const options = {
        center: [location.localisation.lng, location.localisation.lat],
        offset: [-150, -50],
        padding: this.mapPadding,
      };

      if (zoom) {
        options.zoom = zoom;
      }

      const panning = this.mapAsyncActions.easeTo(options);

      panning
        .then(() => {
          this.showPopin(location.id);
          this.mapMoving = false;
        });
    },
    isQuartierVisible(quartierID) {
      return !this.currentQuartier || this.currentQuartier.id === quartierID;
    },
    backToAll() {
      this.currentQuartier = false;
      this.showMarkers = false;

      return this.fitMapToBounds(this.maxBounds, this.mapPadding);
    },
    showQuartier(quartier) {
      if (this.currentQuartier && this.currentQuartier.id === quartier.id) {
        return;
      }

      this.currentQuartier = quartier;
      this.showMarkers = false;

      this.fitMapToBounds(quartier.bounds, this.mapPadding)
        .then(() => {
          this.showMarkers = true;
        });
    },
    fitMapToBounds(bounds, padding = this.mapPadding) {
      this.mapMoving = true;

      if (!this.mapAsyncActions) {
        return Promise.resolve(false);
      }

      return this.mapAsyncActions.fitBounds(bounds, { linear: true, padding })
        .then(() => {
          this.mapMoving = false;
        });
    },
    getQuartierBounds(quartier) {
      if (quartier.zone.features) {
        return utils.getBounds(quartier.zone.features[0].geometry.coordinates[0]);
      }

      return false;
    },
    getBoundsCenter(bounds) {
      return bounds.getCenter();
    },
    poiByQuartier(quartier) {
      const polygon = turf.polygon(quartier.zone.features[0].geometry.coordinates);

      return this.pois.filter((poi) => {
        if (poi.id_quartier && poi.id_quartier === quartier) {
          return true;
        }

        const point = turf.point([parseFloat(poi.localisation.lng), parseFloat(poi.localisation.lat)]);
        return turf.booleanPointInPolygon(point, polygon);
      });
    },
    addPoisToMySelection() {
      this.currentQuartier.pois.forEach((poi) => {
        this[ADD_ITEM]({
          type: this.typeSelection,
          item: poi,
        });
      });
    },
    getClusteredMarkersComponent(quartierId) {
      return this.$refs.map.$slots.default.find(slot => slot.key === `markers-${quartierId}`).componentInstance;
    },
    getDescription(item) {
      if (item.description_commercial) {
        return `<p>${item.description_commercial}</p>`;
      }

      return item.description;
    },
    ...mapMutations('selection', [ADD_ITEM, REMOVE_ITEM]),
  },
  created() {
    // We need to set mapbox-gl library here in order to use it in template
    this.mapbox = Mapbox;
  },
  activated() {
    this.isActivated = true;

    if (this.$route.query.spotId) {
      this.showOnMap(this.$route.query.spotId);
    }

    // If map already loaded
    if (this.mapAsyncActions) {
      this.$refs.map.map.resize();
      this.recenter();
    }
  },
  deactivated() {
    this.isActivated = false;
    this.selectedLocationId = null;
    this.recenter();
  },
  watch: {
    isA11yActivate() {
      if (this.isActivated) {
        this.$refs.map.map.resize();
        this.recenter();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.quartiers {
  position: relative;

  .intro {
    position: absolute;
    z-index: 2;
    top: 0;
    right: 0;
    left: 0;
    padding-bottom: 40px;

    &::before {
      content: '';
      display: block;
      position: absolute;
      z-index: -1;
      top: 0;
      right: -100px;
      bottom: 0;
      left: -200px;
      background-color: $white-alt;
    }

    .back-btn {
      position: absolute;
      z-index: 10;
      top: calc(100% + 30px);
      left: 0;
    }
  }

  .map-container {
    height: $map-height + 90;
  }
}

.carousel-container {
  position: relative;
  margin-left: -90px;

  .controls {
    display: flex;
    justify-content: flex-end;
    margin-top: 35px;

    > * {
      margin-left: 15px;
    }
  }
}

.map-title {
  margin-bottom: 0;
  font-size: 5.5rem;
  font-weight: $book-weight;
}

.map-description {
  margin-top: 20px;
  margin-bottom: 0;
  font-size: 30px;
  font-weight: $book-weight;
  line-height: 1.4;
}

.quartier-intro {
  display: flex;
  align-items: center;

  .map-title {
    flex: 1 1 auto;
  }

  .quartier-selection {
    flex: 0 0 auto;
    margin-left: 40px;
  }
}

.quartier-selection {
  padding: 20px 0;
  color: $red;
  font-size: 2.2rem;
  font-weight: $bold-weight;
  text-transform: uppercase;

  .icon {
    font-size: 2.7rem;
  }
}

.quartier-marker {
  display: flex;
  flex-direction: column;
  align-items: center;

  .pin {
    display: block;
    width: 90px;
  }

  .label {
    max-width: 400px;
    margin-top: 15px;
    padding: 15px 35px 13px;
    border-radius: 50px;
    background-color: $white;
    box-shadow: 0 3px 15px rgba(10, 10, 10, 0.08);
    font-size: 2.5rem;
    font-weight: $regular-weight;
    line-height: 1.3;
    text-align: center;
    text-transform: uppercase;
  }

  .count {
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;

    .icon {
      display: block;
      font-size: 6.5rem;
    }

    .count-label {
      position: absolute;
      z-index: 2;
      top: 45%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: $white;
      font-size: 2.6rem;
      font-weight: $regular-weight;
      line-height: 1.2;
      text-align: center;
    }
  }
}
</style>
