<template>
  <div class="wrapper-card">
    <div class="card-container" @click="onClick">
      <div class="card-top">
        <img :src="imageUrl ? imageUrl : require('@/assets/images/no-img.jpg')" class="image" alt="">
        <span class="shape"></span>
        <div v-if="enVenteIci" class="buy-here">
          <i class="icon icon-ticket"></i>
          {{ $t('page-detail.buyHere') }}
        </div>
      </div>
      <div class="card-content">
        <div class="coup-de-coeur" v-if="auteur">{{$t('gourmande.coups-de-coeur.coup-de-coeur-de', { auteur })}}</div>
        <div class="title">{{ title }}</div>
        <div class="rte-content" v-html="description"></div>
        <div class="location" v-if="localisation"><i class="icon icon-pin"></i>{{ localisation }}</div>

        <p v-if="linkPath" class="read-more-link">
          {{$t('ctaMore')}}
          <i class="icon icon-arrow-right"></i>
        </p>
      </div>
    </div>
    <div class="card-fav" v-if="typeSelection">
      <SelectionButton :type="typeSelection" :item="itemSelection" :big="true" />
    </div>
  </div>
</template>

<script>
import SelectionButton from '@/components/SelectionButton';

export default {
  components: {
    SelectionButton,
  },
  props: {
    imageUrl: {},
    title: String,
    description: String,
    typeSelection: String,
    itemSelection: Object,
    linkPath: {},
    auteur: {},
    localisation: {},
    enVenteIci: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onClick() {
      if (this.linkPath) {
        this.$router.push(this.linkPath);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
$header-height: 385px;

.buy-here {
  position: absolute;
  z-index: 5;
  top: 20px;
  right: 20px;
}

.title {
  margin-bottom: 30px;
  color: $black;
  font-size: 3.6rem;
}

.card-content {
  padding: 60px 80px 80px;
}

.read-more-link {
  margin-top: 50px;
}

.coup-de-coeur {
  margin-bottom: 20px;
  color: $pink;
  font-size: 1.9rem;
  text-transform: uppercase;
}

.location {
  margin-top: 40px;

  + .location {
    margin-top: 5px;
  }
}

.card-top {
  height: 385px;
}

.card-fav {
  top: $header-height - 60px;
  right: 75px;
}

.rte-content {
  font-size: 2.8rem;
  font-weight: $book-weight;

  ::v-deep p {
    strong {
      font-weight: $bold-weight;
    }
  }
}
</style>
