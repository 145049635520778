var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"clustered-markers"},[_vm._l((_vm.formeMarkers),function(marker,index){return _c('MglGeojsonLayer',{key:("shape-" + (marker.id)),attrs:{"sourceId":("shape-" + index),"layerId":("shape-" + index),"source":{
      type: 'geojson',
      data: Object.assign({}, {id: ("shape-" + index)},
        marker),
    },"layer":{
      type: 'line',
      paint: {
        'line-color': '#62bdfd',
        'line-width': 8,
        'line-opacity': 0.7,
      },
      layout: {
        'line-join': 'round',
        'line-cap': 'round',
      },
    }}})}),_vm._l((_vm.geoJSON),function(feature){return _c('MglMarker',{key:feature.properties.id,attrs:{"coordinates":feature.geometry.coordinates,"anchor":"bottom"},on:{"click":function($event){return _vm.onMarkerClick(feature.properties.id)}}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.visible && !_vm.isClustered(feature.properties.id)),expression:"visible && !isClustered(feature.properties.id)"}],staticClass:"marker",attrs:{"slot":"marker"},slot:"marker"},[_c('div',{staticClass:"map-pin-container"},[_c('i',{staticClass:"icon icon-map-pin"}),_c('i',{staticClass:"icon icon-pin"})]),_c('span',{staticClass:"label"},[_vm._v(_vm._s(feature.properties.titre))])])])}),_vm._l((_vm.clusters),function(feature){return _c('MglMarker',{key:feature.properties.cluster_id,attrs:{"coordinates":feature.geometry.coordinates,"anchor":"center"},on:{"click":function($event){return _vm.onClusterClick(feature)}}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.visible),expression:"visible"}],staticClass:"cluster",attrs:{"slot":"marker"},slot:"marker"},[_c('div',{staticClass:"count"},[_c('i',{staticClass:"icon icon-map-pin"}),_c('span',{staticClass:"count-label"},[_vm._v(_vm._s(feature.properties.point_count))])])])])})],2)}
var staticRenderFns = []

export { render, staticRenderFns }