<template>
  <transition name="modal">
    <div class="modal-container" v-if="visible" :class="{'modal-open': visible}">
      <div class="overlay" @click="close"></div>
      <div class="modal">
        <button class="close" @click="close"><span class="cross"></span></button>
        <div class="modal-header" v-if="$slots.title">
          <slot name="title"></slot>
        </div>
        <slot></slot>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
    };
  },
  methods: {
    close(emitEvent = true) {
      this.visible = false;
      if (emitEvent) {
        this.$emit('close');
      }
    },
    open() {
      this.visible = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-container {
  display: none;
  // display: flex;
  position: fixed;
  z-index: 100;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  flex-direction: column;
  align-items: center;
  padding-top: 100px;
  padding-bottom: 400px;

  &.modal-open {
    display: flex;
  }
}

.a11y {
  .modal-container {
    top: $a11y-image-height;
    padding-top: 50px;
    padding-bottom: 50px;

    &.modal-open {
      .modal {
        // width: 75%;
      }

      .filter {
        margin-bottom: 25px;
        font-size: 25px;
      }
    }
  }
}

.overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: $overlay-color;
}

.modal {
  position: relative;
  width: 83%;
  max-height: 98%;
  margin: auto;
  padding: 90px 150px 120px;
  border-radius: 80px;
  background-color: $white;
  box-shadow: 0 4px 94px rgba($true-black, 0.15);
}

.modal-header {
  margin-bottom: $spacing;
  padding-right: 90px;
}

::v-deep .modal-label {
  margin: 0;
  color: $grey-alt;
  text-transform: uppercase;

  .icon {
    margin-right: 15px;
    color: $pink;
  }
}

.close {
  position: absolute;
  top: 90px;
  right: 120px;
  width: 90px;
  height: 90px;
  padding: 0;
  border: 4px solid $pink;
  border-radius: 50%;
  background-color: transparent;

  .cross {
    display: inline-block;
    position: relative;
    width: 35px;
    height: 35px;
    vertical-align: middle;

    &::after,
    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      height: 4px;
      border-radius: 4px;
      background-color: $pink;
    }

    &::after {
      transform: translate(-50%, -50%) rotate(45deg);
    }

    &::before {
      transform: translate(-50%, -50%) rotate(-45deg);
    }
  }
}

.modal-enter-active,
.modal-leave-active {
  transition: opacity $transition-duration ease;
}

.modal-enter,
.modal-leave-to {
  opacity: 0;
}
</style>
