<template>
  <Page
    :title="$route.params.parent"
  >
    <PageDetail
      v-if="lieu"
      :typeSelection="`${$route.params.parent}/${$route.params.type}`"
      :selectionItem="lieu"
      :title="lieu.titre"
      :enVenteIci="lieu.enVenteIci"
      :accesMobiliteReduite="lieu.accesMobiliteReduite"
      :includePassTourisme="lieu.includePassTourisme"
      :image="lieu.image && lieu.image.large ? lieu.image.large : false"
    >
      <template v-slot:location v-if="lieu.adresse">
        <span class="label" v-html="formatAdresse(lieu.adresse)"></span>
      </template>
      <template v-slot:categories v-if="lieu.typeEquipement">{{ lieu.typeEquipement }}</template>

      <template v-slot:about>
        <div class="rte-content">
          <p v-if="lieu.description_commercial"><strong v-html="nl2br(lieu.description_commercial)"></strong></p>
          <p v-html="nl2br(lieu.description)" v-if="lieu.description"></p>
        </div>
      </template>

      <template v-slot:prices>
        <div v-for="tarif in lieu.tarifs" :key="tarif.label" class="bloc-tarif">
          <h4 class="card-title">{{ tarif.label }}</h4>
          <p v-if="tarif.min != ''" class="line-tarif line-tarif-min">
            <span class="label">{{$t('tarifs.min')}}</span>
            <span class="value">{{ tarif.min }} €</span>
          </p>
          <p v-if="tarif.max != ''" class="line-tarif line-tarif-max">
            <span class="label">{{$t('tarifs.max')}}</span>
            <span class="value">{{ tarif.max }} €</span>
          </p>
          <!-- <p v-if="tarif.infos != ''" class="line-tarif line-tarif-infos">
            <span class="label">{{$t('tarifs.extra')}}</span>
            <span class="value">{{ tarif.infos }}</span>
          </p> -->
          <p v-if="tarif.comp != ''" class="line-tarif line-tarif-infos">
            <span class="label">{{$t('tarifs.extra')}}</span>
            <span class="value">{{ tarif.comp }}</span>
          </p>
        </div>
      </template>

      <template v-slot:horaires>
        <HoraireItem
          v-for="(date, index) in lieu.dates"
          :key="index"
          :horaire="date"
        />
      </template>

      <template v-slot:acces v-if="lieu.acces">
        <p>{{ lieu.acces }}</p>
      </template>
    </PageDetail>
  </Page>
</template>

<script>
import utils from '@/utils';
import PageDetail from '@/components/pages/PageDetail.vue';
import Page from '@/views/Page.vue';
import HoraireItem from '@/components/HoraireItem.vue';

export default {
  components: {
    PageDetail,
    Page,
    HoraireItem,
  },
  methods: {
    nl2br(text) {
      return utils.nl2br(text);
    },
    formatAdresse(value) {
      return utils.formatAdresse(value);
    },
  },
  computed: {
    lieu() {
      const methodId = `${this.$route.params.parent}/getItemById`;

      const lieu = this.$store.getters[methodId] ? this.$store.getters[methodId](this.$route.params.id, this.$route.params.type) : false;

      return lieu;
    },
  },
  // mounted() {
  //   if (!this.lieu) {
  //     this.$router.push('/');
  //   }
  // },
  // activated() {
  //   if (!this.lieu) {
  //     this.$router.push('/');
  //   }
  // },
};
</script>
