<template>
  <div class="page-detail">
    <div class="header">
      <div class="intro">
        <BackLink
          :backTo="`/${this.typeSelection}`"
          :title="title"
        />
        <SelectionButton :type="typeSelection" :item="selectionItem" :withText="true" />
      </div>
      <img :src="image ? image : require('@/assets/images/no-img-large.jpg')" class="hero-img" alt="">
      <div class="content-header">
        <h2 class="title">{{title}}</h2>

        <div class="main-date" v-if="$slots.date">
          <slot name="date"></slot>
        </div>

        <div class="infos">
          <p class="info-item" v-if="$slots.location">
            <i class="icon icon-pin"></i>
            <slot name="location"></slot>
          </p>

          <p class="info-item" v-if="$slots.categories">
            <i class="icon icon-categories"></i>
            <slot name="categories"></slot>
          </p>
        </div>

        <div class="extra" v-if="isEnVenteIci || accesMobiliteReduite ||includePassTourisme">
          <div v-if="isEnVenteIci" class="extra-item">
            <i class="icon icon-ticket"></i>
            <span>{{ $t('page-detail.buyHere') }}</span>
          </div>

          <div v-if="accesMobiliteReduite" class="extra-item">
            <i class="icon icon-a11y"></i>
            <span>{{ $t('page-detail.accessibility') }}</span>
          </div>

          <div v-if="includePassTourisme" class="extra-item">
            <img src="@/assets/images/agenda/pass_tourisme.jpg" alt="">
            <span>{{ $t('page-detail.includePassTourisme') }}</span>
          </div>
        </div>
      </div>
    </div>

    <div class="content">
      <div class="menu" v-if="countTabs > 1">
        <button
          class="menu-item"
          @click="currentTab = tab"
          :class="{ active: currentTab === tab }"
          v-for="(tabData, tab) in tabs"
          v-show="tabData.visible"
          :key="tab"
        >
          {{ $t(tabData.title, lang) }}
          <span class="arrow-right"></span>
        </button>
      </div>

      <div class="tabs">
        <transition name="slide-fade" mode="out-in">
          <div
            v-for="(tabData, tab) in tabs"
            v-if="currentTab === tab && tabData.visible"
            :key="tab"
            class="tab-content about scroll-area"
          >
            <div class="inner-scroll-area">
              <h3 class="tab-title">{{ $t(tabData.title, lang) }}</h3>
              <slot :name="tabData.slotName"></slot>
            </div>
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import BackLink from '@/components/BackLink.vue';
import SelectionButton from '@/components/SelectionButton.vue';

export default {
  components: {
    BackLink,
    SelectionButton,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    typeSelection: {
      type: String,
      required: true,
    },
    selectionItem: {
      type: Object,
      required: true,
    },
    image: String,
    date: String,
    location: String,
    categories: String,
    enVenteIci: Boolean,
    accesMobiliteReduite: Boolean,
    includePassTourisme: Boolean,
    backTo: String,
    lang: {
      type: [String, Boolean],
      default: false,
    },
  },
  data() {
    return {
      currentTab: 'about',
    };
  },
  computed: {
    ...mapGetters('map', ['getItemById']),
    lieu() {
      return this.getItemById(this.$route.params.id, this.$route.meta.type);
    },
    isEnVenteIci() {
      return this.enVenteIci;
    },
    tabs() {
      return {
        about: {
          title: 'page-detail.about',
          slotName: 'about',
          visible: !!this.$slots.about,
        },
        prices: {
          title: 'page-detail.prices',
          slotName: 'prices',
          visible: !!this.$slots.prices,
        },
        hours: {
          title: 'page-detail.hours',
          slotName: 'horaires',
          visible: !!this.$slots.horaires,
        },
        'metro-tram': {
          title: 'page-detail.metro-tram',
          slotName: 'acces',
          visible: !!this.$slots.acces,
        },
      };
    },
    countTabs() {
      return Object.values(this.tabs).filter(tab => tab.visible).length;
    },
  },
};
</script>

<style lang="scss" scoped>
$pill-height: 110px;

.header {
  position: relative;
  z-index: 1;
  padding-bottom: 100px;

  &::before {
    content: '';
    display: block;
    position: absolute;
    z-index: -1;
    bottom: 0;
    left: -50vw;
    width: 200vw;
    height: 100vh;
    background-color: $white;
  }
}

.a11y {
  .header {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 50px;

    &::before {
      display: none;
    }

    .intro {
      flex: 0 0 100%;
    }

    .hero-img {
      max-width: 24%;
      height: 220px;
      margin-right: 40px;
    }

    .content-header {
      flex: 0 1 70%;
      width: 1170px;
      overflow: hidden;

      .infos {
        margin-top: 20px;
        margin-bottom: 20px;
      }
    }
  }

  .content {
    max-height: 1115px;
    padding-top: 130px;
  }
}

.intro {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 50px;

  .back-link {
    margin-bottom: 0;
  }
}

.infos {
  display: flex;
  flex-flow: wrap;
  align-items: center;
  margin-top: 50px;
  color: $grey-alt;
  line-height: 2;

  .info-item {
    display: flex;
    align-items: center;
    margin-right: 70px;
    margin-bottom: 0;
    color: $grey-alt;
    font-size: 2.5rem;
    line-height: 1.3;
    text-transform: uppercase;

    &:last-child {
      margin-right: 0;
    }

    .icon {
      margin-right: 20px;
    }
  }
}

.extra {
  display: inline-flex;
  position: absolute;
  top: 100%;
  align-items: center;
  height: $pill-height;
  padding: 10px 70px 10px 50px;
  transform: translateY(-50%);
  border: 3px solid $white-grey;
  border-radius: 150px;
  background-color: $white;
  font-size: 2.2rem;

  .extra-item {
    display: flex;
    align-items: center;
  }

  > * {
    &:not(:last-child) {
      margin-right: 50px;
    }
  }

  .icon {
    font-size: 3rem;
  }

  img {
    height: 40px;
    margin-right: 10px;
  }
}

.hero-img {
  width: 100%;
  max-width: 100%;
  height: 550px;
  object-fit: cover;
  margin-bottom: 55px;
  overflow-y: hidden;
  border-radius: 30px;
}

.title {
  margin-bottom: 25px;
  font-size: 7rem;
  font-weight: $bold-weight;
}

.main-date {
  margin-bottom: 50px;
  font-size: 55px;
  font-weight: 400;

  .small {
    font-size: 0.7em;
  }
}

.content {
  display: flex;
  height: 100%;
  max-height: 2000px;
  padding-top: 100px;

  .tabs {
    flex: 1 1 auto;
    padding-right: 130px;
  }

  .tab-title {
    margin-bottom: 35px;
    font-size: 5rem;
  }
}

.tab-content {
  height: 100%;

  &.scroll-area {
    margin-top: -40px;

    &::after {
      content: '';
      display: block;
      position: absolute;
      z-index: 5;
      right: 0;
      bottom: -1px;
      left: 0;
      height: 50px;
      background: linear-gradient(to top, $white-alt 0%, rgba($white-alt, 0) 100%);
      pointer-events: none;
    }

    .inner-scroll-area {
      padding-bottom: 50px;
    }
  }
}

.icon-ticket {
  color: $pink;
}

.icon-a11y {
  color: $blue;
}

.icon-ticket,
.icon-a11y {
  margin-right: 10px;
}

.lieu-pass {
  margin-right: 0;
}

.menu {
  flex: 0 0 auto;
  width: 300px;
  margin: 0;
  margin-right: 130px;
  padding: 0;
  list-style: none;

  .menu-item {
    display: flex;
    align-items: center;
    width: 100%;
    margin: auto;
    margin-bottom: 45px;
    padding: 0;
    overflow: visible;
    transition: font-weight $transition-duration ease;
    border: none;
    background: transparent;
    font-size: 3.7rem;
    font-weight: $book-weight;
    text-align: left;
    cursor: pointer;

    .arrow-right {
      display: inline-block;
      position: relative;
      width: 50px;
      height: 50px;
      margin-left: auto;

      &::after {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        width: 16px;
        height: 16px;
        transform: translate(-20%, -50%) rotate(45deg);
        transition: all $transition-duration ease;
        border-top: 3px solid $grey-alt2;
        border-right: 3px solid $grey-alt2;
      }

      &::before {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        width: 25px;
        height: 3px;
        transform: translate(-50%, -50%) scaleX(0);
        transform-origin: right;
        transition: transform $transition-duration ease;
        background-color: $grey-alt2;
      }
    }

    &.active {
      color: $pink;

      .arrow-right::after {
        transform: translate(-20%, -50%) rotate(45deg);
        border-color: $pink;
      }

      .arrow-right::before {
        transform: translate(-50%, -50%) scaleX(1);
        background-color: $pink;
      }
    }
  }
}

// .menu button
.slide-fade-enter-active {
  transition: all $transition-duration ease;
}

.slide-fade-leave-active {
  transition: all $transition-duration cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter {
  transform: translateX(-10px);
  opacity: 0;
}

.slide-fade-leave-to {
  transform: translateX(10px);
  opacity: 0;
}
</style>
