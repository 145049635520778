import utils from '@/utils';
import WordpressAjax from '@/wordpress-ajax';
import {
  UPDATE_ACTIVITES,
} from '@/store/mutation-types';
import i18n from '@/i18n';
import imageJungle from '@/assets/images/as-jungle.jpg';

export default {
  namespaced: true,
  state: {
    activites: {},
  },
  mutations: {
    [UPDATE_ACTIVITES](state, activites) {
      state.activites = activites;

      state.activites.fr.unshift({
        id: 'as_de_la_jungle',
        titre: 'Les As de la Jungle',
        description_commercial: 'Les As de la Jungle, vous connaissez ? L’Office de Tourisme et les studios toulousains TAT Productions s’associent pour vous faire vivre un parcours inédit dans la ville rose. Votre mission : explorer Toulouse en famille et retrouver les célèbres animaux du dessin animé culte : Maurice, Junior, Miguel, Batricia, Gilbert, Al et Bob. Ce jeu interactif est idéal pour les familles et permet de découvrir la ville à votre rythme en vous amusant.',
        description: 'Grâce à votre kit découverte disponible à l’accueil, et à l’application «Toulouse en famille» que vous téléchargez sur votre smartphone ou votre tablette, partez à la recherche des animaux des As qui se sont égarés dans Toulouse. A chacune des 6 étapes, un petit jeu vous est proposé afin de faire apparaître les animaux en réalité augmentée sur votre écran. Vous pouvez prendre des photos souvenirs originales de vos enfants à côté des As et ils peuvent ensuite coller les autocollants des animaux au bon endroit sur leur livret découverte. Petit bonus en fin de parcours: une photo souvenir avec tous les animaux réunis! A vous de jouer!<br><br>Bon à savoir: <br>&bull; Le kit découverte est en vente à l’accueil de l’Office de Tourisme (3€). <br>&bull; L’application «Toulouse en famille» est gratuite et très facile à télécharger via IOS ou Android.<br>&bull; Pour effectuer le circuit complet, comptez environ 2h. <br>&bull; Le jeu n’a pas de limitation dans la durée. Une fois l’application téléchargée elle est valable tout le temps. <br>&bull; Vous devrez accepter les données d’accès à la galerie photos et à la géolocalisation afin que l’application fonctionne correctement.',
        image: {
          small: imageJungle,
          large: imageJungle,
        },
        enVenteIci: true,
      });
    },
  },
  getters: {
    getItemsOfCurrentLang: state => type => utils.getItemsOfCurrentLang(state[type], i18n.locale),
    getItemById: (state, getters) => (id, type) => getters.getItemsOfCurrentLang(type).find(lieu => lieu.id === id),
  },
  actions: {
    updateFromAPI(context) {
      return Promise.all([
        WordpressAjax.get('get_activites'),
      ])
        .then((res) => {
          context.commit(UPDATE_ACTIVITES, res[0]);
        });
    },
  },
};
