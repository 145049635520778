import Vue from 'vue';
import VueGtag from 'vue-gtag';
import './font-icons/font-icons.font';
import App from './App.vue';
import router from './router';
import store from './store';
import i18n from './i18n';
import './filters';
import config from './config';

Vue.config.productionTip = false;

Vue.use(VueGtag, {
  config: {
    id: config.googleAnalytics.trackingID,
  },
}, router);

new Vue({
  router,
  store,
  i18n,
  render: h => h(App, { ref: 'app' }),
  methods: {
    showSelectionModal() {
      this.$refs.app.showSelectionModal();
    },
  },
}).$mount('#app');
