<template>
  <PageMap
    :locations="lieux"
    typeSelection="decouvrir/lieux"
    :fullDescription="true"
    :autoBounds="true"
    class="page-10-lieux"
  >
    <div class="bloc-10-lieux">
      <img :src="require(`@/assets/images/10-lieux-${$i18n.locale}.jpg`)" alt="" srcset="">
      <div class="label">
        <i class="icon icon-ticket"></i> {{$t('decouvrir.10-lieux.card')}}
      </div>
    </div>
  </PageMap>
</template>

<script>
import { mapGetters } from 'vuex';
import PageMap from '@/components/pages/PageMap.vue';

export default {
  components: {
    PageMap,
  },
  computed: {
    ...mapGetters('decouvrir', ['getItemsOfCurrentLang']),
    lieux() {
      return this.getItemsOfCurrentLang('lieux');
    },
  },
};
</script>

<style lang="scss" scoped>
.page-10-lieux {
  position: relative;

  .bloc-10-lieux {
    position: absolute;
    z-index: 20;
    bottom: 50px;
    left: 90px;
    width: 360px;
    overflow: hidden;
    border-radius: 10px;
    box-shadow: 0 6px 15px rgba($true-black, 0.2);

    img {
      display: block;
      width: 100%;
    }

    .label {
      display: flex;
      position: absolute;
      right: 20px;
      bottom: 30px;
      left: 20px;
      align-items: center;
      justify-content: center;
      color: $white;
      font-size: 2.2rem;
      font-weight: $regular-weight;
      text-align: center;

      .icon {
        margin-right: 10px;
        color: $pink;
        font-size: 3.5rem;
      }
    }
  }
}
</style>
