<template>
  <div class="wrapper-card">
    <div class="card-container" @click="cardClick">
      <div class="card-top">
        <img :src="imageUrl ? imageUrl : require('@/assets/images/no-img.jpg')" class="image" alt="">
        <span class="shape"></span>
      </div>
      <div class="card-content">
        <div class="title">{{ title }}</div>
      </div>
    </div>
    <div class="card-fav">
      <SelectionButton :type="typeSelection" :item="itemSelection" />
    </div>
  </div>
</template>

<script>
import SelectionButton from '@/components/SelectionButton';

export default {
  components: {
    SelectionButton,
  },
  props: {
    imageUrl: {},
    title: String,
    typeSelection: String,
    itemSelection: Object,
  },
  methods: {
    cardClick() {
      this.$emit('cardClick');
    },
  },
};
</script>

<style lang="scss" scoped>
$header-height: 260px;

.title {
  color: $black;
  font-size: 2.3rem;
  text-transform: uppercase;
}

.card-top {
  height: $header-height;
}

.card-fav {
  top: $header-height - 50px;
}

.rte-content {
  ::v-deep p {
    font-size: 2.8rem;
    font-weight: $book-weight;

    strong {
      font-weight: $bold-weight;
    }
  }
}

.a11y {
  .card-top {
    height: $header-height - 60px;
  }

  .card-fav {
    top: 160px;
  }
}
</style>
