<template>
  <div class="wrapper-card">
    <router-link class="card-container" :to="`${linkPath}${event.id}`">
      <div class="card-top">
        <img :src="event.image && event.image.small ? event.image.small : require('@/assets/images/no-img.jpg')" class="image" alt="">
        <span class="shape"></span>
        <div v-if="event.enVenteIci" class="buy-here">
          <i class="icon icon-ticket"></i>
          {{ $t('page-detail.buyHere') }}
        </div>
      </div>
      <div class="card-content">
        <div class="date" v-if="event.nextDate">{{ event.nextDate.formatted.date }}</div>
        <div class="title">{{ event.titre}}</div>
        <div class="category" v-if="!hideCategory">{{ event.displayCategorie }}</div>
        <div class="location" v-if="lieu">
          <i class="icon icon-pin"></i> {{ lieu }}
        </div>
      </div>
    </router-link>
    <div class="card-fav">
      <SelectionButton :type="typeSelection" :item="event" />
    </div>
  </div>
</template>

<script>
import SelectionButton from '@/components/SelectionButton';

export default {
  components: {
    SelectionButton,
  },
  props: {
    event: Object,
    hideCategory: {
      default: false,
      type: Boolean,
    },
    typeSelection: {
      type: String,
      required: true,
    },
    linkPath: {
      type: String,
      required: true,
    },
  },
  computed: {
    lieu() {
      return this.event.lieu;
    },
  },
};
</script>

<style lang="scss" scoped>
.buy-here {
  position: absolute;
  z-index: 5;
  top: 20px;
  right: 20px;
}

.date {
  margin-bottom: 10px;
  color: $pink;
  font-size: 2rem;
  text-transform: uppercase;
}

.title {
  color: $black;
  font-size: 3rem;
  text-transform: uppercase;
}

.category {
  margin-top: 15px;
  color: $grey-alt;
  font-size: 2rem;
  text-transform: uppercase;
}

.location {
  padding-top: 50px;
}

</style>
