import WordpressAjax from '@/wordpress-ajax';
import { UPDATE_CULINAIRES, UPDATE_MARCHES, UPDATE_COUPS_DE_COEUR } from '@/store/mutation-types';
import i18n from '@/i18n';
import utils from '../../utils';

export default {
  namespaced: true,
  state: {
    allCulinaires: [],
    marches: {},
    coeurs: [],
  },
  mutations: {
    [UPDATE_CULINAIRES](state, culinaires) {
      state.allCulinaires = culinaires;
    },
    [UPDATE_MARCHES](state, marches) {
      state.marches = marches;
    },
    [UPDATE_COUPS_DE_COEUR](state, coeurs) {
      state.coeurs = coeurs;
    },
  },
  getters: {
    mainCulinaire: state => utils.getItemsOfCurrentLang(state.allCulinaires.filter(culinaire => culinaire[i18n.locale] && culinaire[i18n.locale].top), i18n.locale)[0],
    culinaires: state => utils.getItemsOfCurrentLang(state.allCulinaires.filter(culinaire => culinaire[i18n.locale] && !culinaire[i18n.locale].top), i18n.locale),
    getItemById: (state, getters) => (id, type) => getters.getItemsOfCurrentLang(type).find(lieu => lieu.id === id),
    getItemsOfCurrentLang: state => type => utils.getItemsOfCurrentLang(state[type], i18n.locale),
  },
  actions: {
    updateFromAPI(context) {
      return Promise.all([
        WordpressAjax.get('get_culinaires'),
        WordpressAjax.get('get_marches'),
        WordpressAjax.get('get_coeur'),
      ])
        .then((res) => {
          context.commit(UPDATE_CULINAIRES, res[0]);
          context.commit(UPDATE_MARCHES, res[1]);
          context.commit(UPDATE_COUPS_DE_COEUR, res[2]);
        });
    },
  },
};
