import { render, staticRenderFns } from "./DeuxHeures.vue?vue&type=template&id=2b159972&"
import script from "./DeuxHeures.vue?vue&type=script&lang=js&"
export * from "./DeuxHeures.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports