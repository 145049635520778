<template>
  <div class="horaire-item">
    <p class="card-title">{{ horaire | formatDate('long') }}</p>
    <p class="comment" v-if="horaire.complement" v-html="horaire.complement"></p>
    <p class="comment" v-if="horaire.commentaire">{{horaire.commentaire}}</p>
    <p class="comment" v-if="horaire.complementOuverture">{{horaire.complementOuverture}}</p>

    <template v-if="simple">
      <div class="simple-day">{{horaire | formatHoraire}}</div>
    </template>
    <template v-else-if="hasDaysInfos">
      <div class="day" v-for="day in days" :key="day">
        <span class="label">{{$t(`date.days.${day}`)}}</span>
        <span class="value">{{horaire[day] | formatDayHoraire}}</span>
      </div>
    </template>
  </div>
</template>

<script>
import utils from '@/utils';

export default {
  props: {
    horaire: {
      type: Object,
      required: true,
    },
    simple: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      days: ['lundi', 'mardi', 'mercredi', 'jeudi', 'vendredi', 'samedi', 'dimanche'],
    };
  },
  computed: {
    hasDaysInfos() {
      return this.days.map(day => this.horaire[day]).some(horaire => utils.isOpen(horaire));
    },
  },
};
</script>

<style lang="scss" scoped>
.horaire-item {
  margin-bottom: 20px;
  padding: 70px 80px;
  border-radius: 25px;
  background-color: white;

  .card-title {
    margin-bottom: 20px;
    color: $pink;
    font-size: 3.5rem;
    font-weight: $bold-weight;
  }

  .comment {
    margin-bottom: 20px;
    color: $grey;
    font-size: 2.5rem;
    font-style: italic;
    font-weight: $book-weight;
  }

  .simple-day {
    color: $black;
    font-size: 2.5rem;
    font-weight: $book-weight;
  }

  .day {
    display: flex;
    align-items: flex-start;

    &:not(:last-child) {
      margin-bottom: 10px;
    }

    .label {
      flex: 0 0 auto;
      width: 300px;
      margin: 5px 0 0 0;
      color: $grey-alt;
      font-size: 2rem;
      text-transform: uppercase;
    }

    .value {
      flex: 1 1 0%;
      margin: 0;
      color: $black;
      font-size: 2.5rem;
      font-weight: $book-weight;
    }
  }
}
</style>
