import moment from 'moment';
import Mapbox from 'mapbox-gl';
import i18n from '@/i18n';

export default {
  removeDuplicate(array) {
    return array.filter((elem, pos, arr) => arr.indexOf(elem) === pos);
  },
  generateID(string) {
    return string.normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(/ +/g, '-').toLowerCase();
  },
  getMomentDate(date) {
    return moment(date, 'DD/MM/YYYY');
  },
  getMomentHour(hour) {
    return moment(hour, 'HH:mm');
  },
  translateDate(date, format) {
    return i18n.d(date, format);
  },
  formatDate(date, format = 'short') {
    if (!date) {
      return false;
    }
    const today = moment();

    const momentDebut = this.getMomentDate(date.dateDebut);
    const momentFin = this.getMomentDate(date.dateFin);

    // Fin non valide ou debut == fin
    if (!momentFin.isValid() || momentDebut.isSame(momentFin)) {
      if (momentDebut.year() !== moment().year()) {
        return this.translateDate(momentDebut.toDate(), `${format}WithYear`);
      }

      return this.translateDate(momentDebut.toDate(), format);
    }

    if (momentDebut.isBefore(today)) {
      const to = this.translateDate(momentFin.toDate(), `${format}WithYear`);

      return i18n.t('date.until', {
        to,
      });
    }

    let from = this.translateDate(momentDebut.toDate(), format);
    let to = this.translateDate(momentFin.toDate(), format);

    // Si date de fin et de début pas de la meme année
    if (!momentDebut.isSame(momentFin, 'year')) {
      from = this.translateDate(momentDebut.toDate(), `${format}WithYear`);
      to = this.translateDate(momentFin.toDate(), `${format}WithYear`);
    } else if (momentDebut.isSame(momentFin, 'month')) {
      // Si le même mois
      from = momentDebut.date();
    }

    return i18n.t('date.fromTo', {
      from,
      to,
    });
  },
  formatHoraire(horaires) {
    if (!horaires) {
      return '';
    }

    if (horaires.heureOuverture === '' && horaires.heureFermeture === '' && horaires.heureOuverture2 === '' && horaires.heureFermeture2 === '') {
      return '';
    }

    if (horaires.heureOuverture !== '' && horaires.heureFermeture !== '' && horaires.heureOuverture2 !== '' && horaires.heureFermeture2 !== '') {
      return i18n.t('date.horaireFromToAnd', {
        horaireFrom1: horaires.heureOuverture,
        horaireTo1: horaires.heureFermeture,
        horaireFrom2: horaires.heureOuverture2,
        horaireTo2: horaires.heureFermeture2,
      });
    }

    if (horaires.heureOuverture !== '' && horaires.heureFermeture !== '') {
      return i18n.t('date.horaireFromTo', {
        horaireFrom: horaires.heureOuverture,
        horaireTo: horaires.heureFermeture,
      });
    }

    if (horaires.heureOuverture2 !== '' && horaires.heureFermeture2 !== '') {
      return i18n.t('date.horaireFromTo', {
        horaireFrom: horaires.heureOuverture2,
        horaireTo: horaires.heureFermeture2,
      });
    }

    if (horaires.heureOuverture !== '' && horaires.heureOuverture2 !== '') {
      return i18n.t('date.horaireAtAnd', {
        horaire1: horaires.heureOuverture,
        horaire2: horaires.heureOuverture2,
      });
    }

    return i18n.t('date.horaireFromTo', {
      horaireFrom: horaires.heureOuverture,
      horaireTo: horaires.heureFermeture2,
    });
  },
  isOpen(horaires) {
    if (!horaires) {
      return false;
    }

    if (horaires.heureDebut1 === '' && horaires.heureFin1 === '' && horaires.heureDebut2 === '' && horaires.heureFin2 === '') {
      return false;
    }

    return true;
  },
  formatDayHoraire(horaires) {
    if (!horaires) {
      return '';
    }

    if (horaires.heureDebut1 === '' && horaires.heureFin1 === '' && horaires.heureDebut2 === '' && horaires.heureFin2 === '') {
      return i18n.t('date.close');
    }

    if (horaires.heureDebut1 !== '' && horaires.heureFin1 !== '' && horaires.heureDebut2 !== '' && horaires.heureFin2 !== '') {
      return i18n.t('date.horaireFromToAnd', {
        horaireFrom1: horaires.heureDebut1,
        horaireTo1: horaires.heureFin1,
        horaireFrom2: horaires.heureDebut2,
        horaireTo2: horaires.heureFin2,
      });
    }

    if (horaires.heureDebut1 !== '' && horaires.heureFin1 !== '') {
      return i18n.t('date.horaireFromTo', {
        horaireFrom: horaires.heureDebut1,
        horaireTo: horaires.heureFin1,
      });
    }

    if (horaires.heureDebut2 !== '' && horaires.heureFin2 !== '') {
      return i18n.t('date.horaireFromTo', {
        horaireFrom: horaires.heureDebut2,
        horaireTo: horaires.heureFin2,
      });
    }

    if (horaires.heureDebut1 !== '' && horaires.heureDebut2 !== '') {
      return i18n.t('date.horaireAtAnd', {
        horaire1: horaires.heureDebut1,
        horaire2: horaires.heureDebut2,
      });
    }

    return i18n.t('date.horaireFromTo', {
      horaireFrom: horaires.heureDebut1,
      horaireTo: horaires.heureFin2,
    });
  },

  nl2br(value) {
    return String(value).replace(/\n/g, '<br>');
  },

  getNextDate(dates) {
    const nextDate = dates.find(date => this.isInFutureDate(date));

    if (!nextDate) {
      return dates[0];
    }

    return nextDate;
  },
  isInFutureDate(date) {
    const now = moment();
    const momentDebut = this.getMomentDate(date.dateDebut);
    const momentFin = this.getMomentDate(date.dateFin);

    // Fin non valide ou meme debut et fin
    if (!momentFin.isValid() || momentDebut.isSame(momentFin)) {
      return momentDebut.isSameOrAfter(now, 'day');
    }

    return momentFin.isSameOrAfter(now, 'day');
  },
  isDateToday(date) {
    const now = moment();
    const momentDebut = this.getMomentDate(date.dateDebut);
    const momentFin = this.getMomentDate(date.dateFin);

    if (!momentFin.isValid() || momentDebut.isSame(momentFin)) {
      return now.isSame(momentDebut, 'day');
    }

    return now.isBetween(momentDebut, momentFin, 'day');
  },
  isDateInWeek(date) {
    const now = moment();
    const momentDebut = this.getMomentDate(date.dateDebut);
    const momentFin = this.getMomentDate(date.dateFin);

    let isInWeek = false;
    let weekDay = now;

    for (let day = 1; day <= 7; day += 1) {
      if (!momentFin.isValid() || momentDebut.isSame(momentFin)) {
        isInWeek = isInWeek || weekDay.isSame(momentDebut, 'day');
      } else {
        isInWeek = isInWeek || weekDay.isBetween(momentDebut, momentFin, 'day');
      }
      weekDay = weekDay.add(1, 'days');
    }

    return isInWeek;
  },
  dayLabel(addDayToToday = 0) {
    const now = moment();
    now.add(addDayToToday, 'day');

    return this.translateDate(now.toDate(), 'weekday');
  },
  getItemsOfCurrentLang(items, locale) {
    if (!items || !locale) {
      console.info('No items or no locale');
      return [];
    }

    if (items && items[locale]) {
      return items[locale];
    }

    return items.map((item) => {
      const res = {
        ...item,
      };

      const dataLang = res[locale];

      const langs = ['fr', 'en', 'es'];

      langs.forEach((lang) => {
        delete res[lang];
      });

      return {
        ...res,
        ...dataLang,
      };
    });
  },
  getAdresse(location) {
    if (location.localisation.address) {
      return location.localisation.address;
    }

    if (location.adresse) {
      return location.adresse.adresse1 ? location.adresse.adresse1 : location.adresse.adresse2;
    }

    return '';
  },
  formatAdresse(adresse) {
    if (!adresse) {
      return false;
    }

    let res = '';

    if (adresse.adresse1) {
      res += `${adresse.adresse1}, `;
    }

    if (adresse.adresse2) {
      res += `${adresse.adresse2}, `;
    }

    res += `${adresse.codepostal} ${adresse.commune}`;
    return res;
  },
  getBounds(coordinates) {
    const bounds = new Mapbox.LngLatBounds();

    coordinates.forEach((coordinate) => {
      bounds.extend(coordinate);
    });

    return bounds;
  },
  extendBounds(bounds, coordinates) {
    coordinates.forEach((coordinate) => {
      bounds.extend(coordinate);
    });

    return bounds;
  },
  validateEmail(email) {
    /* eslint-disable */
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
    /* eslint-enable */
  },
  wait(duration = 500) {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve();
      }, duration);
    });
  },
};
