<template>
  <Modal :visible="visible" ref="modal" @close="emitCancel">
    <img class="icon-hand" src="@/assets/images/hand.png" alt="">
    <div class="rte-content" v-html="$t('no-activity.content')"></div>
    <div class="btns">
      <button class="btn outline turquoise large lower" @click="handleBackClick">{{$t('no-activity.back-home')}} (<span class="count">{{delay}}s</span>)</button>
      <button class="btn turquoise large lower" @click="handleCancelClick">{{$t('no-activity.still-here')}}</button>
    </div>
  </Modal>
</template>

<script>
import Modal from '@/components/modals/Modal';
import config from '@/config';

export default {
  data() {
    return {
      visible: false,
      delay: null,
      interval: null,
    };
  },
  components: {
    Modal,
  },
  methods: {
    open() {
      this.$refs.modal.open();
      this.startTimer();
    },
    close() {
      if (this.interval) {
        clearInterval(this.interval);
      }

      this.$refs.modal.close(false);
    },
    startTimer() {
      this.delay = config.popupValidateDelay;

      if (this.interval) {
        clearInterval(this.interval);
      }

      this.interval = setInterval(() => {
        if (this.delay <= 0) {
          clearInterval(this.interval);
          this.emitBack();
          this.close();
          return;
        }
        this.delay -= 1;
      }, 1000);
    },
    handleCancelClick() {
      this.emitCancel();
      this.close();
    },
    handleBackClick() {
      this.emitBack();
      this.close();
    },
    emitBack() {
      if (this.interval) {
        clearInterval(this.interval);
      }

      this.$emit('back');
    },
    emitCancel() {
      if (this.interval) {
        clearInterval(this.interval);
      }

      this.$emit('cancel');
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .modal {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  height: 900px;
  text-align: center;

  .rte-content {
    p {
      font-size: 5rem;
      line-height: 1.8;
    }
  }

  .icon-container {
    text-align: center;
  }

  .icon-hand {
    width: 60px;
    margin: auto;
    margin-bottom: 50px;
  }
}

.modal-container {
  padding-bottom: 900px;
}

.btns {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: $spacing-small-alt;

  .btn {
    margin: 0 15px;
  }

  .count {
    display: inline-block;
    width: 60px;
    text-align: center;
  }
}
</style>
