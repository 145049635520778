<template>
    <div :style="style" ref="lavContainer"></div>
</template>

<script>
import lottie from 'lottie-web';
import heartData from '@/assets/animations/heart.json';
import checkData from '@/assets/animations/check.json';

export default {
  props: {
    icon: String,
    height: Number,
    width: Number,
    options: {
      type: Object,
      default() {
        return {
          loop: true,
          autoplay: true,
          renderer: 'svg',
        };
      },
    },
  },
  data() {
    return {
      animationDatas: {
        heart: heartData,
        check: checkData,
      },
      style: {
        width: this.width ? `${this.width}px` : '100%',
        height: this.height ? `${this.height}px` : '100%',
        overflow: 'hidden',
        margin: '0 auto',
      },
    };
  },
  mounted() {
    if (!this.animationDatas[this.icon]) {
      throw new Error('No corresponding animation');
    }

    this.anim = lottie.loadAnimation({
      container: this.$refs.lavContainer,
      renderer: 'svg',
      loop: this.options.loop !== false,
      autoplay: this.options.autoplay !== false,
      animationData: this.animationDatas[this.icon],
    });
    this.$emit('animCreated', this.anim);
  },
};
</script>
