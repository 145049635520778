<template>
  <div>
    <p class="warning" v-if="$i18n.locale !== 'fr'"><span class="info-icon"><i class="icon icon-info"></i></span> <span v-html="$t('decouvrir.visites-guidees.not-available')"></span></p>

    <PageEventsListing
      :events="filteredEvents"
      :hideCategory="true"
      typeSelection="decouvrir/visites-guidees"
      linkPath="/decouvrir/visites-guidees/event/"
    >

    </PageEventsListing>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import PageEventsListing from '@/components/pages/PageEventsListing.vue';

export default {
  components: {
    PageEventsListing,
  },
  computed: {
    ...mapState('agenda', ['categories', 'events']),
    filteredEvents() {
      return this.events.filter(event => this.isVisibleWithFilter(event));
    },
  },
  methods: {
    isVisibleWithFilter(event) {
      return event.categories.indexOf('visites-guidees') !== -1;
    },
  },
};
</script>
