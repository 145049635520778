<template>
  <div class="wrapper-card map-popup-card">
    <div class="overlay" @click="$emit('close')"></div>
    <div class="card-container" @click="onClick">
      <div class="card-top">
        <div class="close" @click.stop="$emit('close')">
          <div class="cross"></div>
        </div>
        <img :src="imageUrl ? imageUrl : require('@/assets/images/no-img.jpg')" class="image" alt="">
        <span class="shape"></span>
      </div>
      <div class="card-content">
        <div class="location" v-if="localisation"><i class="icon icon-pin"></i>{{ localisation }}</div>
        <div class="title">{{ title }}</div>
        <div class="rte-content" v-if="description" v-html="description"></div>
        <p class="read-more-link" v-if="url">
          {{ $t('ctaMore') }}
          <i class="icon icon-arrow-right"></i>
        </p>
      </div>
    </div>
    <div class="card-fav">
      <SelectionButton :type="typeSelection" :item="itemSelection" :big="true" />
    </div>
  </div>
</template>

<script>
import SelectionButton from '@/components/SelectionButton';

export default {
  components: {
    SelectionButton,
  },
  props: {
    imageUrl: {},
    title: {
      type: String,
      required: true,
    },
    typeSelection: {
      type: String,
      required: true,
    },
    itemSelection: {
      type: Object,
      required: true,
    },
    localisation: {},
    url: {},
    description: String,
  },
  methods: {
    onClick() {
      if (this.url) {
        this.$router.push(this.url);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
$header-height: 385px;

.map-popup-card {
  width: 800px;

  &::before {
    content: '';
    display: block;
    position: absolute;
    z-index: 5;
    top: 50%;
    left: -17px;
    width: 0;
    height: 0;
    border-width: 19px 20px 19px 0;
    border-style: solid;
    border-color: transparent $white transparent transparent;
  }

  .card-container {
    box-shadow: 0 19px 55px rgba(0, 0, 0, 0.2);
  }

  .card-fav {
    top: auto;
    right: 50px;
    bottom: 50px;

    .selection-button {
      width: 80px;
      height: 80px;
    }
  }

  .card-content {
    padding: 40px 80px 80px 80px;

    .location {
      margin-bottom: 30px;
    }

    .title {
      margin-bottom: 40px;
      font-size: 45px;
    }

    .rte-content {
      margin-bottom: 60px;
    }
  }
}

.title {
  margin-bottom: 30px;
  color: $black;
  font-size: 3.6rem;
}

.rte-content {
  ::v-deep p {
    font-size: 2.8rem;
    font-weight: $book-weight;

    strong {
      font-weight: $bold-weight;
    }
  }
}

.close {
  display: flex;
  position: absolute;
  z-index: 10;
  top: 40px;
  right: 50px;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: $white;

  .cross {
    display: inline-block;
    position: relative;
    width: 40px;
    height: 40px;
    vertical-align: middle;

    &::after,
    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      height: 4px;
      border-radius: 4px;
      background-color: $pink;
    }

    &::after {
      transform: translate(-50%, -50%) rotate(45deg);
    }

    &::before {
      transform: translate(-50%, -50%) rotate(-45deg);
    }
  }
}
</style>
