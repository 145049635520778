<template>
  <PageCards
    :mainCard="mainCulinaire"
    mainCardSurtitle="$t('gourmande.cardFull.headerTitle')"
    :cards="culinaires"
    typeSelection="gourmande/culinaires"
  >
    <template slot="deco">
      <DecoCard
        surtitleIcon="fork"
        :surtitle="$t('gourmande.card.headerTitle')"
        :image="require('@/assets/images/gourmande/bonnesad.jpg')"
      >
        <p class="medium-title">{{ $t('gourmande.card.contentTitle') }}</p>
        <p>{{ $t('gourmande.card.contentTxt') }}</p>
      </DecoCard>
    </template>
  </PageCards>
</template>

<script>
import { mapGetters } from 'vuex';
import PageCards from '@/components/pages/PageCards';
import DecoCard from '@/components/cards/DecoCard';

export default {
  components: {
    PageCards,
    DecoCard,
  },
  computed: {
    ...mapGetters('gourmande', ['mainCulinaire', 'culinaires']),
  },
};
</script>
