<template>
  <div class="meteo-icon" v-html="require(`!html-loader!../assets/images/meteo/${icon}.svg`)" v-if="icon !== -1"></div>
</template>

<script>
export default {
  props: {
    icon: {
      default: -1,
      type: Number,
    },
  },
};
</script>

<style lang="scss" scoped>
svg {
  display: block;
  width: 100%;
}
</style>
