import WordpressAjax from '@/wordpress-ajax';
import { UPDATE_WEATHER, WEATHER_OK } from '@/store/mutation-types';

export default {
  namespaced: true,
  state: {
    isSet: false,
    today: {},
    tomorrow: {},
    afterTomorrow: {},
  },
  mutations: {
    [UPDATE_WEATHER](state, { day, weather }) {
      if (!state[day]) {
        return false;
      }

      state[day] = {
        temperature: Number.parseInt(weather.max, 10),
        icon: Number.parseInt(weather.icon, 10),
      };
      return true;
    },
    [WEATHER_OK](state) {
      state.isSet = true;
    },
  },
  actions: {
    updateFromAPI(context) {
      return WordpressAjax.get('get_meteo')
        .then((res) => {
          context.commit(UPDATE_WEATHER, {
            day: 'today',
            weather: res[0],
          });
          context.commit(UPDATE_WEATHER, {
            day: 'tomorrow',
            weather: res[1],
          });
          context.commit(UPDATE_WEATHER, {
            day: 'afterTomorrow',
            weather: res[2],
          });
          context.commit(WEATHER_OK);
        });
    },
  },
};
