<template>
  <PageMap
    :locations="marches"
    typeSelection="gourmande/marches"
    :autoBounds="true"
  />
</template>

<script>
import { mapGetters } from 'vuex';
import PageMap from '@/components/pages/PageMap.vue';

export default {
  components: {
    PageMap,
  },
  computed: {
    ...mapGetters('gourmande', ['getItemsOfCurrentLang']),
    marches() {
      return this.getItemsOfCurrentLang('marches');
    },
  },
};
</script>
