<template>
  <div class="update">
    <router-link to="/">Home</router-link>
    <button class="btn turquoise" @click="update">Mettre a jour les contenus</button>

    <div class="loader" :class="{'js-loading': loading}">
      <div class="spinner"></div>
    </div>
    <div class="status" v-if="status">{{status}}</div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  data() {
    return {
      loading: false,
      status: false,
    };
  },
  computed: {
    title() {
      return this.$route.matched[0].meta.title;
    },
  },
  methods: {
    ...mapActions(['updateAllData']),
    update() {
      this.loading = true;

      return this.updateAllData()
        .then(() => {
          this.loading = false;
        })
        .catch((error) => {
          this.status = `Error: ${error}`;
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.update {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  padding: $spacing;

  > * {
    margin-top: $spacing;
  }
}

.status {
  position: relative;
  z-index: 7;
  margin-top: 50px;
  color: $white;
  text-align: center;
}

.loader {
  display: flex;
  position: fixed;
  z-index: 5;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  padding: 3rem 0;
  transition: opacity $transition-duration ease;
  opacity: 0;
  background-color: rgba($black, 0.8);
  pointer-events: none;

  &.js-loading {
    opacity: 1;
    pointer-events: all;
  }

  .spinner {
    width: 50px;
    height: 50px;
    margin: $spacing-alt auto;
    animation: rotate 2s infinite linear;
    border: 5px solid $white;
    border-radius: 50%;
    border-top-color: rgba($white, 0.2);
  }

  @keyframes rotate {
    from { transform: rotate(0deg); }
    to { transform: rotate(360deg); }
  }
}
</style>
