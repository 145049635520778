<template>
  <div class="clustered-markers">
    <MglGeojsonLayer
      v-for="(marker, index) in formeMarkers"
      :key="`shape-${marker.id}`"
      :sourceId="`shape-${index}`"
      :layerId="`shape-${index}`"
      :source="{
        type: 'geojson',
        data: {
          id: `shape-${index}`,
          ...marker,
        },
      }"
      :layer="{
        type: 'line',
        paint: {
          'line-color': '#62bdfd',
          'line-width': 8,
          'line-opacity': 0.7,
        },
        layout: {
          'line-join': 'round',
          'line-cap': 'round',
        },
      }"
    />

    <MglMarker
      :coordinates="feature.geometry.coordinates"
      v-for="feature in geoJSON"
      :key="feature.properties.id"
      anchor="bottom"
      @click="onMarkerClick(feature.properties.id)"
    >
      <div
        slot="marker"
        class="marker"
        v-show="visible && !isClustered(feature.properties.id)"
      >
        <div class="map-pin-container">
          <i class="icon icon-map-pin"></i>
          <i class="icon icon-pin"></i>
        </div>
        <span class="label">{{feature.properties.titre}}</span>
      </div>
    </MglMarker>

    <MglMarker
      v-for="feature in clusters"
      :key="feature.properties.cluster_id"
      :coordinates="feature.geometry.coordinates"
      anchor="center"
      @click="onClusterClick(feature)"
    >
      <div
        slot="marker"
        class="cluster"
        v-show="visible"
      >
        <div class="count">
          <i class="icon icon-map-pin"></i>
          <span class="count-label">{{feature.properties.point_count}}</span>
        </div>
      </div>
    </MglMarker>
  </div>
</template>

<script>
import Mapbox from 'mapbox-gl';
import { MglMarker, MglGeojsonLayer } from 'vue-mapbox';
import Supercluster from 'supercluster';
import mapUtils from '@/mapUtils';

export default {
  components: {
    MglMarker,
    MglGeojsonLayer,
  },
  props: {
    locations: Array,
    zoom: Number,
    visible: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      clustersGeojson: [],
      clusterIndex: null,
      maxZoom: mapUtils.maxZoom,
    };
  },
  watch: {
    zoom(newZoom) {
      this.updateClusters(newZoom);
    },
    locations() {
      this.clusterIndex.load(this.geoJSON);
      this.updateClusters(this.zoom);
    },
  },
  computed: {
    clusters() {
      return this.clustersGeojson.filter(feature => feature.properties.cluster);
    },
    clusterData() {
      const res = {};
      this.geoJSON.forEach((location) => {
        res[location.properties.id] = this.getClusterID(location.properties.id);
      });
      return res;
    },
    maxBounds() {
      return mapUtils.getBounds(this.locations);
    },
    geoJSON() {
      return this.locations.filter(location => !location.hidden).map(item => this.getGeoJSON(item));
    },
    formeMarkers() {
      return this.locations
        .filter(location => !!location.formes)
        .map((location) => {
          const geoJSON = JSON.parse(location.formes);
          return geoJSON.drawnItems.features[0];
        })
        .filter(item => !!item);
    },
  },
  methods: {
    getGeoJSON(item) {
      return {
        type: 'Feature',
        properties: {
          ...item,
        },
        geometry: {
          type: 'Point',
          coordinates: [item.localisation.lng, item.localisation.lat, 0.0],
        },
      };
    },
    updateClusters(zoom) {
      if (this.locations.length === 0) {
        return;
      }

      this.clustersGeojson = this.clusterIndex.getClusters(
        [this.maxBounds.getWest(), this.maxBounds.getSouth(), this.maxBounds.getEast(), this.maxBounds.getNorth()],
        Math.floor(zoom),
      );
    },
    clusterChildrens(clusterId) {
      return this.clusterIndex.getLeaves(clusterId, Infinity);
    },
    isClustered(spotID) {
      return !!this.clusterData[spotID];
    },
    getClusterID(spotID) {
      const spotCluster = this.clusters.find(cluster => this.clusterChildrens(cluster.id).some(leave => leave.properties.id === spotID));

      if (!spotCluster) {
        return false;
      }

      return spotCluster.id;
    },
    onClusterClick(feature) {
      const bounds = new Mapbox.LngLatBounds();

      const features = this.clusterIndex.getLeaves(feature.properties.cluster_id, Infinity);

      features.forEach((feat) => {
        bounds.extend(feat.geometry.coordinates);
      });

      this.$emit('clusterClick', bounds);
    },
    onMarkerClick(markerId) {
      this.$emit('markerClick', markerId);
    },
  },
  mounted() {
    this.clusterIndex = new Supercluster({
      radius: 210,
      maxZoom: this.maxZoom - 1,
    });

    this.clusterIndex.load(this.geoJSON);
    this.updateClusters(this.zoom);
  },
};
</script>

<style lang="scss" scoped>
.marker {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 210px;
  font-size: 2.2rem;
  font-weight: $bold-weight;
  line-height: 1.2;
  text-align: center;
  text-transform: uppercase;

  .map-pin-container {
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;

    .icon-map-pin {
      display: block;
      color: $pink;
      font-size: 7.5rem;
    }

    .icon-pin {
      position: absolute;
      z-index: 2;
      top: 47%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: $white;
      font-size: 2.7rem;
      text-align: center;
    }
  }

  .label {
    position: absolute;
    top: 0;
    width: 100%;
    padding-top: 90px;
  }

  &.small {
    font-size: 1.5rem;

    .pin {
      width: 30px;
    }
  }
}

.cluster {
  display: flex;
  flex-direction: column;
  align-items: center;

  .count {
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;

    .icon {
      display: block;
      color: $pink;
      font-size: 10.5rem;
    }

    .count-label {
      position: absolute;
      z-index: 2;
      top: 45%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: $white;
      font-size: 4rem;
      font-weight: $regular-weight;
      line-height: 1.2;
      text-align: center;
    }
  }
}
</style>
