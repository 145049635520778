<template>
  <div class="util-buttons" :class="{'on-home': onHome}">
    <button
      class="util-btn selection-btn"
      @click="onSelectionClick"
    >
      <span class="selection-count" v-if="hasSelection">{{totalItems}}</span>
      <span class="icon-container"><i class="icon icon-heart"></i></span>
      <span class="label">{{ $t('selection.buttonLabel') }}</span>
    </button>

    <div class="btns-group">
      <button
        class="util-btn lang-btn"
        @click="onLangClick(lang.locale)"
        v-for="lang in availableLangs"
        :key="lang.locale"
      >
        <span class="icon-container">
          <span class="flag"><img :src="require(`../assets/images/flags/${lang.locale}.svg`)" alt=""></span>
        </span>
        <span class="label">{{onHome ? lang.locale : lang.label}}</span>
      </button>

      <button
        class="util-btn a11y-btn"
        @click="onA11yClick"
      >
        <template v-if="isA11yActivate">
          <span class="icon-container"><i class="icon icon-accessib"></i></span>
          <span class="label">{{ $t('a11y.disableButtonLabel') }}</span>
          <!-- <span>{{ $t('a11y.disableButtonLabel') }}</span> -->
        </template>
        <template v-else>
          <span class="icon-container"><i class="icon icon-a11y"></i></span>
          <span class="label">{{ $t('a11y.enableButtonLabel') }}</span>
        </template>
      </button>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { mapGetters, mapActions, mapState } from 'vuex';

export default {
  props: {
    onHome: {
      default: false,
    },
  },
  computed: {
    ...mapGetters('selection', ['totalItems', 'hasSelection']),
    ...mapState(['isA11yActivate']),
    availableLangs() {
      return this.langs.filter(lang => lang.locale !== this.$i18n.locale);
    },
  },
  methods: {
    ...mapActions(['toggleA11y']),
    onSelectionClick() {
      this.$root.showSelectionModal();
    },
    onLangClick(locale) {
      this.$i18n.locale = locale;
      moment.locale(locale);
    },
    onA11yClick() {
      this.toggleA11y();
    },
  },
  data() {
    return {
      langs: [
        {
          locale: 'fr',
          label: 'Français',
        },
        {
          locale: 'en',
          label: 'English',
        },
        {
          locale: 'es',
          label: 'Español',
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.util-buttons {
  display: flex;
  align-items: center;

  .btns-group {
    display: flex;
    align-items: center;
  }

  .util-btn {
    border: 0;
    appearance: none;
    background: transparent;
    color: $black;
    font-size: 2.7rem;
    font-weight: $regular-weight;
  }

  .lang-btn {
    .flag {
      display: block;
      width: 70px;
      height: 70px;
      overflow: hidden;
      border-radius: 50%;

      img {
        display: block;
        width: 100%;
        height: auto;
      }
    }
  }

  &:not(.on-home) {
    z-index: 1;
    left: 0;
    flex-direction: column;
    width: 310px;
    padding: 260px 0 140px;
    background-color: $white;
    clip-path: url(#menuMask);

    .util-btn {
      display: flex;
      flex-direction: column;
      align-items: center;
      max-width: 200px;
      margin-bottom: $spacing-xsmall-alt;
    }

    .btns-group {
      flex-direction: column;
    }

    .selection-btn {
      position: relative;

      .selection-count {
        display: flex;
        position: absolute;
        z-index: 1;
        top: 0;
        right: 30px;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background-color: $red;
        color: $white;
        font-size: 1.7rem;
        font-weight: $bold-weight;
        line-height: 1;
        text-align: center;
      }
    }

    .icon-container {
      display: block;
      position: relative;
      width: 110px;
      height: 110px;
      margin-bottom: 17px;
      border-radius: 50%;
      background-color: $light-grey;

      > * {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      .icon-heart {
        color: $red;
        font-size: 3.6rem;
      }

      .icon-a11y,
      .icon-accessib {
        font-size: 5rem;
      }
    }
  }

  &.on-home {
    position: absolute;
    right: 0;
    bottom: 750px;
    left: 0;
    justify-content: center;
    transition: bottom $transition-duration ease;

    .util-btn {
      color: $white;
    }

    .selection-btn {
      display: flex;
      position: relative;
      align-items: center;
      justify-content: center;
      width: 600px;
      height: 200px;
      margin-right: $spacing-small;
      border-radius: 200px;
      background: $white-alt;
      box-shadow: 0 12px 55px rgba($true-black, 0.25);
      color: $red;
      font-size: 4.2rem;

      .selection-count {
        display: none;
      }

      .icon-container {
        order: 3;
        margin-left: 30px;

        .icon {
          font-size: 5rem;

          &.icon-accessib {
            padding: 0 20px;
          }
        }
      }
    }

    .btns-group {
      height: 200px;
      padding: 0 100px;
      border: 4px solid $white;
      border-radius: 200px;

      .a11y-btn {
        border-left: 4px solid rgba($white, 0.25);
      }
    }

    .lang-btn {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 10px $spacing-xsmall-alt;

      .label {
        font-size: 2rem;
        text-transform: uppercase;
      }

      .flag {
        margin-bottom: 20px;
        box-shadow: 0 4px 15px rgba(0, 0, 0, 0.25);
      }
    }

    .a11y-btn {
      margin-left: $spacing-xsmall-alt;
      padding-left: $spacing-small-alt;

      .icon {
        margin-bottom: 15px;
        color: $white;
        font-size: 7rem;
      }

      .label {
        display: block;
        font-size: 2rem;
        text-transform: uppercase;
      }
    }
  }
}
</style>
