import moment from 'moment';
import WordpressAjax from '@/wordpress-ajax';
import { ADD_ITEM, REMOVE_ITEM, RESET } from '@/store/mutation-types';
import filters from '@/filters';
import i18n from '@/i18n';

export default {
  namespaced: true,
  state: {
    'decouvrir/lieux': [],
    'decouvrir/sites': [],
    'decouvrir/visites-guidees': [],
    'decouvrir/quartiers_pois': [],
    'activites/activites': [],
    'agenda/agenda': [],
    'gourmande/culinaires': [],
    'gourmande/marches': [],
    'gourmande/coeurs': [],
    'region/une-heure': [],
    'region/deux-heures': [],
  },
  getters: {
    isInSelection: state => (type, id) => state[type].indexOf(id) > -1,
    totalItems: state => Object.keys(state).reduce((accumulator, stateItem) => accumulator + state[stateItem].length, 0),
    hasSelection: (state, getters) => getters.totalItems > 0,
    getItemsOfCurrentLang: (state, getters, rootState, rootGetters) => (type) => {
      const typeSplit = type.split('/');
      const parentType = typeSplit[0];
      let childType = typeSplit[1];
      let methodId = `${parentType}/getItemById`;

      if (type === 'agenda/agenda' || type === 'decouvrir/visites-guidees') {
        methodId = 'agenda/getEventById';
      }

      if (type === 'gourmande/culinaires') {
        childType = 'allCulinaires';
      }

      const items = state[type].map((id) => {
        const item = rootGetters[methodId](id, childType);

        if (!item) {
          return false;
        }

        const selectionItem = {
          id,
          web: item.web ? item.web : false,
          image: item.image && item.image.small ? item.image.small : false,
          titre: item.titre,
        };
        let linkType = 'spot';

        switch (type) {
          case 'agenda/agenda':
          case 'decouvrir/visites-guidees':
            selectionItem.date = filters.formatDate(item.nextDate);
            selectionItem.lieu = item.lieu;
            selectionItem.description = item.aPropos ? filters.truncate(filters.striphtml(item.aPropos), 90) : false;
            linkType = 'event';
            break;
          case 'gourmande/culinaires':
          case 'gourmande/coeurs':
            selectionItem.description = item.description ? filters.truncate(filters.striphtml(item.description), 90) : false;
            break;
          case 'decouvrir/quartiers_pois':
            selectionItem.lieu = item.lieu;
            selectionItem.description = filters.truncate(filters.striphtml(item.description_commercial ? item.description_commercial : item.description), 90);
            break;
          case 'gourmande/marches':
          case 'decouvrir/sites':
            selectionItem.lieu = item.lieu;
            selectionItem.description = item.description_commercial ? filters.truncate(filters.striphtml(item.description_commercial), 90) : false;
            break;
          case 'activites/activites':
            selectionItem.description = item.description_commercial ? filters.truncate(filters.striphtml(item.description_commercial), 90) : false;
            break;
          case 'region/une-heure':
          case 'region/deux-heures':
            selectionItem.description = filters.truncate(filters.striphtml(item.description_courte), 90);
            break;
          default:
            break;
        }

        selectionItem.link = `/${type}/${linkType}/${item.id}`;

        return selectionItem;
      });

      return items.filter(item => !!item);
    },
  },
  mutations: {
    [RESET](state) {
      Object.keys(state).forEach((stateItem) => {
        state[stateItem] = [];
      });
    },
    [ADD_ITEM](state, { type, id }) {
      state[type].push(id);
    },
    [REMOVE_ITEM](state, { type, id }) {
      const index = state[type].indexOf(id);
      if (index > -1) {
        state[type].splice(index, 1);
      }
    },
  },
  actions: {
    toggleItem({ getters, commit }, { type, id }) {
      if (getters.isInSelection(type, id)) {
        commit(REMOVE_ITEM, { type, id });
      } else {
        commit(ADD_ITEM, { type, id });
      }
    },
    resetSelection({ commit }) {
      commit(RESET);
    },
    sendSelection({ getters }, email) {
      const date = moment().format('DD/MM/YYYY HH:mm');

      const data = {
        action: 'send_favoris',
        data: {
          lang: i18n.locale,
          date,
          email,
          lieux: getters.getItemsOfCurrentLang('decouvrir/lieux'),
          sites: getters.getItemsOfCurrentLang('decouvrir/sites'),
          visites: getters.getItemsOfCurrentLang('decouvrir/visites-guidees'),
          pois: getters.getItemsOfCurrentLang('decouvrir/quartiers_pois'),
          activites: getters.getItemsOfCurrentLang('activites/activites'),
          agenda: getters.getItemsOfCurrentLang('agenda/agenda'),
          culinaires: getters.getItemsOfCurrentLang('gourmande/culinaires'),
          coeurs: getters.getItemsOfCurrentLang('gourmande/coeurs'),
          marches: getters.getItemsOfCurrentLang('gourmande/marches'),
          uneHeure: getters.getItemsOfCurrentLang('region/une-heure'),
          deuxHeures: getters.getItemsOfCurrentLang('region/deux-heures'),
        },
      };

      return WordpressAjax.post(data);
    },
  },
};
