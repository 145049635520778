<template>
  <div class="main-menu" :class="{ a11y: isA11yActivate }">
    <router-link
      class="menu-link"
      v-for="link in links"
      :key="link.url"
      :to="link.url"
      :exact="link.url === '/'"
    >
      <div class="icon-container" v-html="require(`!html-loader!../assets/images/menu/${link.icon}.svg`)"></div>
      <span class="label" v-html="$t(link.label)"></span>
    </router-link>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  data() {
    return {
      links: [
        {
          url: '/',
          label: 'menu.accueil',
          icon: 'accueil',
        },
        {
          url: '/decouvrir',
          label: 'menu.decouvrir',
          icon: 'decouvrir',
        },
        {
          url: '/activites',
          label: 'menu.activites',
          icon: 'activites',
        },
        {
          url: '/agenda',
          label: 'menu.agenda',
          icon: 'agenda',
        },
        {
          url: '/gourmande',
          label: 'menu.gourmande',
          icon: 'gourmande',
        },
        {
          url: '/region',
          label: 'menu.region',
          icon: 'region',
        },
      ],
    };
  },
  computed: {
    ...mapState(['isA11yActivate']),
  },
};
</script>

<style lang="scss" scoped>
.main-menu {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: stretch;
  width: 330px;
  height: 1840px;
  padding: 210px 0 200px;
  background: linear-gradient(251.72deg, #EA2950 41.85%, #EA2638 64.23%, #EB362E 89.28%);
  clip-path: url(#menuMask);

  &.a11y {
    flex-direction: row;
    width: 92%;
    height: 320px;
    padding: 0 310px;
    transform: translateX(-50%);
    background: linear-gradient(-196.1deg, #F06C30 39.16%, #EA2F2E 65.77%, #EA2A58 89.24%);
    clip-path: url(#menuHorizontalMask);

    .menu-link {
      &:nth-child(5),
      &:last-child {
        margin-top: 40px;
      }
    }
  }
}

.menu-link {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  color: $white;
  font-size: 3rem;
  font-weight: $bold-weight;
  text-align: center;
  text-decoration: none;

  &:not(.router-link-active) {
    .icon-container {
      ::v-deep svg {
        .outline {
          > * {
            fill: $white;
          }
        }

        > *:not(.outline) {
          opacity: 0;
        }
      }
    }
  }

  .icon-container {
    margin-bottom: 15px;

    ::v-deep svg {
      * {
        transition: fill 1s ease, opacity 1s ease;
        opacity: 1;
      }
    }
  }
}
</style>
