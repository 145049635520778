import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '@/views/Home.vue';
import Update from '@/views/Update.vue';
import Page from '@/views/Page.vue';
import Decouvrir10Lieux from '@/views/decouvrir/10Lieux.vue';
import SitesMusees from '@/views/decouvrir/SitesMusees.vue';
import VisitesGuidees from '@/views/decouvrir/VisitesGuidees.vue';
import Quartiers from '@/views/decouvrir/Quartiers.vue';
import Marches from '@/views/gourmande/Marches.vue';
import CoupsDeCoeur from '@/views/gourmande/CoupsDeCoeur.vue';
import Culinaires from '@/views/gourmande/Culinaires.vue';
import Activites from '@/views/activites/Activites.vue';
import UneHeure from '@/views/region/UneHeure.vue';
import DeuxHeures from '@/views/region/DeuxHeures.vue';
import Agenda from '@/views/agenda/Agenda.vue';
import Event from '@/views/Event.vue';
import Lieu from '@/views/Lieu.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    // component: () => import(/* webpackChunkName: "10-lieux" */ '@/views/Page10Lieux.vue'),
  },
  {
    path: '/update',
    name: 'update',
    component: Update,
  },
  {
    path: '/:parent/:type/spot/:id/',
    component: Lieu,
  },
  {
    path: '/:parent/:type/event/:id',
    component: Event,
  },
  {
    path: '/decouvrir',
    component: Page,
    meta: {
      title: 'decouvrir',
    },
    children: [
      {
        path: '',
        redirect: '10-lieux',
      },
      {
        path: '10-lieux',
        component: Decouvrir10Lieux,
        meta: {
          title: 'decouvrir.10-lieux',
        },
      },
      {
        path: 'sites',
        component: SitesMusees,
        meta: {
          title: 'decouvrir.sites',
        },
      },
      {
        path: 'visites-guidees',
        component: VisitesGuidees,
        meta: {
          title: 'decouvrir.visites-guidees',
        },
      },
      {
        path: 'quartiers_pois',
        component: Quartiers,
        meta: {
          title: 'decouvrir.quartiers',
        },
      },
    ],
  },
  {
    path: '/gourmande',
    component: Page,
    meta: {
      title: 'gourmande',
    },
    children: [
      {
        path: '',
        redirect: 'culinaires',
      },
      {
        path: 'culinaires',
        component: Culinaires,
        meta: {
          title: 'gourmande.culinaires',
        },
      },
      {
        path: 'marches',
        component: Marches,
        meta: {
          title: 'gourmande.marches',
        },
      },
      {
        path: 'coups-de-coeur',
        component: CoupsDeCoeur,
        meta: {
          title: 'gourmande.coups-de-coeur',
        },
      },
    ],
  },
  {
    path: '/activites',
    component: Page,
    meta: {
      title: 'activites',
    },
    children: [
      {
        path: '',
        redirect: 'activites',
      },
      {
        path: 'activites',
        component: Activites,
      },
    ],
  },
  {
    path: '/region',
    component: Page,
    meta: {
      title: 'region',
    },
    children: [
      {
        path: '',
        redirect: 'une-heure',
      },
      {
        path: 'une-heure',
        component: UneHeure,
        meta: {
          title: 'region.une-heure',
        },
      },
      {
        path: 'deux-heures',
        component: DeuxHeures,
        meta: {
          title: 'region.deux-heures',
        },
      },
    ],
  },
  {
    path: '/agenda',
    component: Page,
    meta: {
      title: 'agenda',
    },
    children: [
      {
        path: '',
        redirect: 'agenda',
      },
      {
        path: 'agenda',
        component: Agenda,
      },
    ],
  },
  {
    path: '*',
    redirect: '/',
  },
];

const router = new VueRouter({
  routes,
});

router.beforeEach((to, from, next) => {
  // console.log(to, from);
  next();

  // TODO DEBUG on preload

//   if (!to.query.spotId && from.path !== to.path && from.path.indexOf(to.path) === 0) {
//     next({
//       path: to.path,
//       query: { spotId: from.params.id },
//     });
//   } else {
//     next();
//   }
});

export default router;
