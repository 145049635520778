export const UPDATE_WEATHER = 'UPDATE_WEATHER';
export const WEATHER_OK = 'WEATHER_OK';

export const UPDATE_EVENTS = 'UPDATE_EVENTS';
export const UPDATE_CATEGORIES = 'UPDATE_CATEGORIES';

export const UPDATE_CULINAIRES = 'UPDATE_CULINAIRES';

export const UPDATE_LIEUX = 'UPDATE_LIEUX';
export const UPDATE_QUARTIERS = 'UPDATE_QUARTIERS';
export const UPDATE_MARCHES = 'UPDATE_MARCHES';
export const UPDATE_QUARTIER_POIS = 'UPDATE_QUARTIER_POIS';
export const UPDATE_SITES = 'UPDATE_SITES';
export const UPDATE_ACTIVITES = 'UPDATE_ACTIVITES';
export const ADD_AS_DE_LA_JUNGLE = 'ADD_AS_DE_LA_JUNGLE';
export const UPDATE_VISITES = 'UPDATE_VISITES';
export const UPDATE_ITEMS = 'UPDATE_ITEMS';
export const UPDATE_COUPS_DE_COEUR = 'UPDATE_COUPS_DE_COEUR';

export const ADD_ITEM = 'ADD_ITEM';
export const REMOVE_ITEM = 'REMOVE_ITEM';
export const RESET = 'RESET';

export const ENABLE_A11Y = 'ENABLE_A11Y';
export const DISABLE_A11Y = 'DISABLE_A11Y';
export const TOGGLE_A11Y = 'TOGGLE_A11Y';

export const SET_LAST_UPDATE = 'SET_LAST_UPDATE';
