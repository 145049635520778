<template>
  <div class="cards-page">
    <div class="scroll-area">
      <div class="inner-scroll-area" @scroll="onScroll">
        <HorizontalCard
          v-if="mainCard"
          :imageUrl="mainCard.image && mainCard.image.small ? mainCard.image.small : false"
          :title="mainCard.titre"
          :description="mainCard[descriptionField]"
          :typeSelection="typeSelection"
          :surtitle="$t('gourmande.cardFull.headerTitle')"
          surtitleIcon="fork"
          :itemSelection="mainCard"
        />

        <slot name="deco-top"></slot>

        <div class="masonry-container">
          <div class="left-column column">
            <slot name='first-card'></slot>

            <SimpleCard
              v-for="card in $slots['first-card'] ? secondColumnElements : firstColumnElements"
              :key="card.id"
              :imageUrl="card.image && card.image.small ? card.image.small : false"
              :title="card.titre"
              :description="card[descriptionField]"
              :typeSelection="typeSelection"
              :itemSelection="card"
              :enVenteIci="card.enVenteIci"
              :linkPath="linkPath ? `${linkPath}${card.id}` : false"
              :auteur="card.auteur ? card.auteur : false"
              :localisation="card.adresse ? card.adresse : false"
            />
          </div>

          <div class="right-column column">
            <div class="tile" v-if="$slots.deco">
              <slot name="deco"></slot>
            </div>
            <SimpleCard
              v-for="card in $slots['first-card'] ? firstColumnElements : secondColumnElements"
              :key="card.id"
              :imageUrl="card.image && card.image.small ? card.image.small : false"
              :title="card.titre"
              :description="card[descriptionField]"
              :typeSelection="typeSelection"
              :itemSelection="card"
              :enVenteIci="card.enVenteIci"
              :linkPath="linkPath ? `${linkPath}${card.id}` : false"
              :auteur="card.auteur ? card.auteur : false"
              :localisation="card.adresse ? card.adresse : false"
            />
          </div>
        </div>
      </div>

      <div class="btn-container" :class="{scrolled: isScrolled}">
        <div class="scroll-btn">
          <i class="icon icon-scroll-arrow"></i>
          <i class="icon icon-scroll-hand"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SimpleCard from '@/components/cards/SimpleCard';
import HorizontalCard from '@/components/cards/HorizontalCard';
import utils from '@/utils';

export default {
  components: {
    SimpleCard,
    HorizontalCard,
  },
  data() {
    return {
      scrollAmount: 0,
    };
  },
  props: {
    mainCard: {
      type: Object,
    },
    mainCardSurtitle: String,
    cards: {
      type: Array,
      required: true,
    },
    typeSelection: {
      type: String,
      required: true,
    },
    linkPath: {
      type: String,
    },
    descriptionField: {
      type: String,
      default: 'description',
    },
  },
  computed: {
    firstColumnElements() {
      return this.cards.filter((_, index) => index % 2 === 0);
    },
    secondColumnElements() {
      return this.cards.filter((_, index) => index % 2 === 1);
    },
    isScrolled() {
      return this.scrollAmount > 500 || this.noScroll;
    },
  },
  methods: {
    onScroll(event) {
      this.scrollAmount = event.currentTarget.scrollTop;
    },
  },
  mounted() {
    utils.wait(500)
      .then(() => this.$root.$emit('fully-loaded', this.$route.path));
  },
};
</script>

<style lang="scss" scoped>
$grid-gap: 50px;

.cards-page {
  height: 100%;
}

.inner-scroll-area {
  padding-bottom: 800px;
}

.masonry-container {
  display: flex;
  margin-right: -$grid-gap * 0.5;
  margin-left: -$grid-gap * 0.5;

  .column {
    flex: 1 0 50%;
    padding: 0;

    > * {
      width: 100%;
      padding: 0 $grid-gap * 0.5 $grid-gap;
    }
  }
}

.horizontal-card {
  margin-bottom: $grid-gap;
}

.a11y {
  .inner-scroll-area {
    padding-bottom: 350px;
  }
}

.btn-container {
  position: absolute;
  z-index: 10;
  right: 0;
  bottom: 550px;
  left: 0;
  text-align: center;

  &.scrolled {
    .scroll-btn {
      transform: translate(-50%, -100px);
      opacity: 0;
      pointer-events: none;
    }
  }

  &:not(.scrolled) {
    .btn {
      transform: translateY(100px);
      opacity: 0;
      pointer-events: none;
    }
  }

  .btn {
    transition: opacity $transition-duration ease, transform $transition-duration ease;
    box-shadow: 0 4px 34px rgba($true-black, 0.25);
  }
}

.scroll-btn {
  position: absolute;
  z-index: 0;
  top: 0;
  left: 50%;
  width: 300px;
  height: 300px;
  transform: translateX(-50%);
  transition: opacity $transition-duration ease, transform $transition-duration ease;
  border-radius: 50%;
  background-color: $white;
  box-shadow: 0 4px 34px rgba($true-black, 0.15);

  .icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: $pink;
    font-size: 13rem;
  }

  .icon-scroll-hand {
    animation: scroll-animate 1.6s infinite ease;
  }

  @keyframes scroll-animate {
    0%,
    100% {
      transform: translate(-50%, -60%);
    }

    30% {
      transform: translate(-50%, -10%);
    }
  }
}

.scroll-area {
  &::after {
    content: '';
    display: block;
    position: absolute;
    z-index: 5;
    right: 0;
    bottom: -1px;
    left: 0;
    height: 800px;
    background: linear-gradient(to top, $white-alt 0%, rgba($white-alt, 0) 100%);
    pointer-events: none;
  }

  .inner-scroll-area {
    padding-bottom: 800px;
  }
}
</style>
