import utils from '@/utils';
import WordpressAjax from '@/wordpress-ajax';
import {
  UPDATE_ITEMS,
} from '@/store/mutation-types';
import i18n from '@/i18n';

export default {
  namespaced: true,
  state: {
    items: {},
  },
  mutations: {
    [UPDATE_ITEMS](state, items) {
      state.items = items;
    },
  },
  getters: {
    'une-heure': state => state.items.filter(item => item.fr.distance === 'une'),
    'deux-heures': state => state.items.filter(item => item.fr.distance === 'deux'),
    getItemsOfCurrentLang: (state, getters) => type => utils.getItemsOfCurrentLang(getters[type], i18n.locale),
    getItemById: (state, getters) => (id, type) => getters.getItemsOfCurrentLang(type).find(lieu => lieu.id === id),
  },
  actions: {
    updateFromAPI(context) {
      return Promise.all([
        WordpressAjax.get('get_region'),
      ])
        .then((res) => {
          context.commit(UPDATE_ITEMS, res[0]);
        });
    },
  },
};
