<template>
  <Modal :visible="visible" ref="modal" :class="{ list: screen === 'list' && hasSelection}">
    <template v-slot:title>
      <p class="modal-label back" v-if="screen === 'mail'" @click="screen = 'list'"><i class="icon icon-arrow-left"></i> {{$t('selection.mail.back')}}</p>
      <p class="modal-label" v-else><i class="icon icon-heart-outline"></i> {{$t('selection.modal.surtitle')}}</p>
      <button class="btn outline reset" @click="resetSelection" v-if="screen === 'list' && hasSelection">
        <i class="icon icon-reset"></i>
        {{$t('selection.reset')}}
      </button>
    </template>

    <transition name="screen-fade" mode="out-in">
      <div class="content-screen" v-if="!hasSelection" key="no-selection">
        <div class="animated-image">
          <IconAnimation icon="heart" :width="200"/>
        </div>
        <div v-html="$t('selection.no-selection.text')" class="text"></div>
        <button class="btn pink" @click="close">
          <i class="icon icon-arrow-left"></i>
          {{$t('selection.no-selection.go-back')}}
        </button>
      </div>

      <div v-else-if="screen === 'list'" key="list" class="wrapper-selection-list">
        <div class="intro">
          <span>{{$t('selection.modal.title')}}</span>
          <button class="btn large turquoise" @click="goToMail">
            <i class="icon icon-mail"></i>
            {{$t('selection.modal.mail')}}
          </button>
        </div>

        <div class="scroll-area white" v-if="hasSelection">
          <transition-group name="transition-list" tag="div" class="transition-container inner-scroll-area">
            <div class="selection-title" key="title-agenda" v-if="agenda.length > 0">{{$t('agenda.title')}}</div>

            <SelectionCard
              @navigate="close()"
              v-for="item in agenda"
              :key="item.id"
              :item="item"
              typeSelection="agenda/agenda"
            />

            <div class="selection-title" key="title-gourmande" v-if="culinaires.length > 0 || marches.length > 0 || coeurs.length > 0">{{$t('gourmande.title')}}</div>

            <SelectionCard
              @navigate="close()"
              v-for="item in culinaires"
              :key="item.id"
              :item="item"
              typeSelection="gourmande/culinaires"
            />

            <SelectionCard
              @navigate="close()"
              v-for="item in marches"
              :key="item.id"
              :item="item"
              typeSelection="gourmande/marches"
            />

            <SelectionCard
              @navigate="close()"
              v-for="item in coeurs"
              :key="item.id"
              :item="item"
              typeSelection="gourmande/coeurs"
            />

            <div class="selection-title" key="title-decouvrir" v-if="lieux.length > 0 || sites.length > 0 || visites.length > 0 || pois.length > 0">{{$t('decouvrir.title')}}</div>

            <SelectionCard
              @navigate="close()"
              v-for="item in lieux"
              :key="item.id"
              :item="item"
              typeSelection="decouvrir/lieux"
            />

            <SelectionCard
              @navigate="close()"
              v-for="item in sites"
              :key="item.id"
              :item="item"
              typeSelection="decouvrir/sites"
            />

            <SelectionCard
              @navigate="close()"
              v-for="item in visites"
              :key="item.id"
              :item="item"
              typeSelection="decouvrir/visites-guidees"
            />

            <SelectionCard
              @navigate="close()"
              v-for="item in pois"
              :key="item.id"
              :item="item"
              typeSelection="decouvrir/quartiers_pois"
            />

            <div class="selection-title" key="title-activites" v-if="activites.length > 0">{{$t('activites.title')}}</div>

            <SelectionCard
              @navigate="close()"
              v-for="item in activites"
              :key="item.id"
              :item="item"
              typeSelection="activites/activites"
            />

            <div class="selection-title" key="title-region" v-if="uneHeure.length > 0 || deuxHeures.length > 0">{{$t('region.title')}}</div>

            <SelectionCard
              @navigate="close()"
              v-for="item in uneHeure"
              :key="item.id"
              :item="item"
              typeSelection="region/une-heure"
            />

            <SelectionCard
              @navigate="close()"
              v-for="item in deuxHeures"
              :key="item.id"
              :item="item"
              typeSelection="region/une-heure"
            />
          </transition-group>
        </div>
      </div>

      <form class="email-form" @submit.prevent="onFormSubmit" :class="{ loading: form.loading }" v-else-if="screen === 'mail'" key="mail">
        <div class="overlay"></div>
        <label class="form-label" for="mail">{{$t('selection.mail.label')}}</label>

        <div class="input-container" @click="showKeyboard">
          <input
            :placeholder="$t('selection.mail.placeholder')"
            :value="form.email"
            class="input"
            @input="onEmailInputChange"
            type="text"
          >
          <div class="cross" @click="emptyEmailInput" v-show="form.email !== ''"></div>
        </div>

        <transition name="fade" mode="out-in">
          <div class="keyboard-wrapper" v-if="form.keyboardVisible">
            <Keyboard
              @onChange="onEmailChange"
              :input="form.email"
              type="email"
              @submit="hideKeyboard"
              class="keyboard"
            />
          </div>

          <form class="no-keyboard" @submit="onFormSubmit" v-else>
            <p class="mentions" v-html="$t('selection.mail.mentions')" @click="showConfidentialite"></p>

            <div class="checkbox-container">
              <input type="checkbox" name="enquete" id="enquete" v-model="form.enquete">
              <label for="enquete" v-html="$t('selection.mail.checkbox')"></label>
            </div>

            <button class="btn large turquoise" type="submit" :disabled="!isEmailValid">
              <i class="icon icon-send"></i>
              <span class="label">{{$t('selection.mail.submit')}}</span>
              <div class="spinner"></div>
            </button>
          </form>
        </transition>
      </form>

      <div class="content-screen" v-else-if="screen === 'mail-send'" key="mail-ok">
        <div class="animated-image">
          <IconAnimation icon="check" :width="200"/>
        </div>
        <div v-html="$t('selection.mail.send-text')" class="text"></div>
        <button class="btn pink" @click="close">{{$t('selection.mail.close')}}</button>
      </div>
    </transition>
  </Modal>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Modal from '@/components/modals/Modal';
import utils from '@/utils';
import Keyboard from '@/components/Keyboard';
import SelectionCard from '@/components/cards/SelectionCard';
import IconAnimation from '@/components/IconAnimation';
import WordpressAjax from '@/wordpress-ajax';
// import decouvrirData from '@/assets/animations/decouvrir.json';

export default {
  data() {
    return {
      visible: false,
      screen: 'list',
      form: {
        enquete: false,
        loading: false,
        email: '',
        keyboardVisible: false,
      },
      confidentialiteVisible: false,
    };
  },
  components: {
    Modal,
    Keyboard,
    IconAnimation,
    SelectionCard,
  },
  computed: {
    ...mapGetters('selection', ['hasSelection', 'getItemsOfCurrentLang']),
    lieux() {
      return this.getItemsOfCurrentLang('decouvrir/lieux');
    },
    sites() {
      return this.getItemsOfCurrentLang('decouvrir/sites');
    },
    visites() {
      return this.getItemsOfCurrentLang('decouvrir/visites-guidees');
    },
    pois() {
      return this.getItemsOfCurrentLang('decouvrir/quartiers_pois');
    },
    activites() {
      return this.getItemsOfCurrentLang('activites/activites');
    },
    agenda() {
      return this.getItemsOfCurrentLang('agenda/agenda');
    },
    culinaires() {
      return this.getItemsOfCurrentLang('gourmande/culinaires');
    },
    marches() {
      return this.getItemsOfCurrentLang('gourmande/marches');
    },
    coeurs() {
      return this.getItemsOfCurrentLang('gourmande/coeurs');
    },
    uneHeure() {
      return this.getItemsOfCurrentLang('region/une-heure');
    },
    deuxHeures() {
      return this.getItemsOfCurrentLang('region/deux-heures');
    },
    isEmailValid() {
      return utils.validateEmail(this.form.email);
    },
  },
  methods: {
    ...mapActions('selection', ['resetSelection', 'sendSelection']),
    open() {
      this.form.enquete = false;
      this.form.email = '';
      this.screen = this.hasSelection ? 'list' : 'no-selection';
      this.$refs.modal.open();
    },
    close() {
      this.$refs.modal.close();
    },
    goToMail() {
      this.screen = 'mail';
    },
    showOk() {
      this.screen = 'mail-send';
      this.form.loading = false;
    },
    onFormSubmit($event) {
      $event.preventDefault();
      this.form.loading = true;

      const promises = [
        this.sendSelection(this.form.email),
      ];

      if (this.form.enquete) {
        promises.push(this.sendEnquete());
      }

      return Promise.all(promises)
        .then((res) => {
          if (!res) {
            this.form.loading = false;
          } else {
            this.showOk();
          }
        })
        .catch(() => {
          this.form.loading = false;
        });
    },
    sendEnquete() {
      const data = {
        action: 'save_enquete',
        data: {
          lang: this.$i18n.locale,
          email: this.form.email,
        },
      };

      return WordpressAjax.post(data);
    },
    onEmailChange(value) {
      this.form.email = value;
    },
    emptyEmailInput() {
      this.form.email = '';
    },
    onEmailInputChange(input) {
      this.form.email = input.target.value;
    },
    showKeyboard() {
      this.form.keyboardVisible = true;
    },
    hideKeyboard() {
      this.form.keyboardVisible = false;
    },
    showConfidentialite() {
      this.confidentialiteVisible = true;
    },
    hideConfidentialite() {
      this.confidentialiteVisible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .modal {
  display: flex;
  flex-direction: column;
  height: 1708px;
  transition: height $transition-duration ease;

  .inner-scroll-area {
    padding-bottom: $spacing;
  }

  .modal-header {
    display: flex;
    align-items: center;

    .btn {
      margin-left: auto;
    }

    .back {
      color: $pink;
    }
  }
}

.modal-container {
  &.list {
    ::v-deep .modal {
      height: 100%;
      padding-bottom: 0;
    }
  }
}

.content-screen {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: auto;
  margin-bottom: auto;
  padding-bottom: $spacing;

  .animated-image {
    margin-bottom: 80px;
  }

  .text {
    max-width: 1050px;
    text-align: center;

    ::v-deep p {
      color: $black;
      font-size: 5rem;
      font-weight: $book-weight;

      &.highlight {
        color: $pink;
      }
    }
  }

  .btn {
    min-width: 280px;
    margin-top: 60px;
    font-size: 2.5rem;
    font-weight: $bold-weight;

    .icon {
      font-size: 2.5rem;
    }
  }
}

.wrapper-selection-list {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  height: 100%;
  min-height: 0;
}

.transition-container {
  position: relative;
  width: 100%;

  > * {
    transition: transform $transition-duration ease, opacity $transition-duration ease;
  }
}

.transition-list-enter,
.transition-list-leave-to {
  transform-origin: center center;
  opacity: 0;
}

.transition-list-leave-active {
  position: absolute;
  z-index: 0;
  width: calc(100% - 30px);
}

.intro {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: $spacing-small;

  span {
    margin-right: auto;
    font-size: 5rem;
    font-weight: $bold-weight;
  }
}

.selection-title {
  margin-top: 130px;
  margin-bottom: 30px;
  color: $pink;
  font-size: 5rem;
  font-weight: $book-weight;

  &:first-child {
    margin-top: 0;
  }
}

.email-form {
  position: relative;
  margin: 100px auto 0 auto;
  text-align: center;

  &.loading {
    .overlay {
      opacity: 1;
      pointer-events: all;
    }

    .btn {
      .spinner {
        opacity: 1;
      }

      .label,
      .icon {
        opacity: 0;
      }
    }
  }

  .overlay {
    position: absolute;
    z-index: 5;
    top: 0;
    right: -50px;
    bottom: 0;
    left: -50px;
    transition: opacity $transition-duration ease;
    opacity: 0;
    background-color: rgba($white, 0.7);
    pointer-events: none;
  }

  .no-keyboard {
    width: 1000px;

    .mentions {
      margin-bottom: 75px;
      padding: 0 40px;
      color: $grey-alt;
      font-size: 3rem;
      font-weight: $book-weight;
      line-height: 1.5;

      span {
        color: $pink;
      }
    }

    .checkbox-container {
      width: 100%;
      margin-bottom: 60px;

      input {
        display: none;
      }

      label {
        display: inline-block;
        position: relative;
        margin: 0;
        transition: color $transition-duration ease;
        color: $grey-alt;
        font-size: 3rem;
        font-weight: $book-weight;
        line-height: 1.5;
        cursor: pointer;

        ::v-deep span {
          display: inline-block;
          padding-left: 90px;
          text-align: left;
        }

        &::before {
          content: '';
          display: inline-block;
          position: absolute;
          top: 3px;
          left: 37px;
          width: 37px;
          height: 37px;
          transition: background-color $transition-duration ease, border-color $transition-duration ease;
          border: 3px solid $grey-alt2;
          border-radius: 5px;
          background-color: transparent;
          vertical-align: middle;
        }
      }

      input:checked ~ label {
        &::before {
          border-color: $turquoise;
          background-color: $turquoise;
        }
      }
    }
  }

  .no-keyboard,
  .keyboard-wrapper {
    margin-top: $spacing-small;
  }

  .btn {
    position: relative;
    z-index: 7;
    margin-top: 80px;
    padding-right: 120px;
    padding-left: 120px;

    > * {
      transition: opacity $transition-duration ease;
    }

    .icon-send {
      margin-right: 30px;
      margin-bottom: 7px;
      vertical-align: middle;
    }

    .spinner {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 60px;
      height: 60px;
      transform: translate(-50%, -50%);
      animation: rotate 2s infinite linear;
      border: 5px solid $white;
      border-radius: 50%;
      border-top-color: rgba($white, 0.2);
      opacity: 0;
    }

    @keyframes rotate {
      from { transform: translate(-50%, -50%) rotate(0deg); }
      to { transform: translate(-50%, -50%) rotate(360deg); }
    }
  }

  .keyboard-wrapper {
    margin-top: $spacing-small-alt;
    margin-right: -70px;
    margin-left: -70px;
  }

  .form-label {
    display: block;
    margin-bottom: 0.9em;
    margin-left: 30px;
    font-size: 5rem;
    font-weight: $bold-weight;
  }

  .form-row {
    display: flex;

    .input-container {
      flex: 1 1 auto;
      margin-right: 35px;
    }
  }

  .input-container {
    position: relative;
    width: 1000px;
    height: 150px;
    margin: auto;

    input {
      width: 100%;
      height: 100%;
      // padding-right: 10px;
      border: 6px solid $light-grey;
      border-radius: 150px;
      color: $black;
      font-size: 4rem;
      font-weight: $book-weight;
      text-align: center;

      @include placeholder {
        opacity: 1;
        color: $grey-alt2;
      }

      &:focus {
        outline: none;
      }
    }

    .icon {
      position: absolute;
      top: 50%;
      left: 60px;
      transform: translateY(-50%);
      color: $grey-alt2;
      font-size: 5rem;
    }

    .cross {
      position: absolute;
      top: 50%;
      right: 60px;
      width: 40px;
      height: 40px;
      transform: translateY(-50%);
      vertical-align: middle;

      &::after,
      &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        height: 4px;
        border-radius: 4px;
        background-color: $pink;
      }

      &::after {
        transform: translate(-50%, -50%) rotate(45deg);
      }

      &::before {
        transform: translate(-50%, -50%) rotate(-45deg);
      }
    }
  }
}

// Screen transitions
.screen-fade-enter-active,
.screen-fade-leave-active {
  transition: all $transition-duration ease;
}

.screen-fade-enter {
  transform: translateX(30px);
  opacity: 0;
}

.screen-fade-leave-to {
  transform: translateX(-30px);
  opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s ease;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

</style>
