<template>
  <PageMap
    :locations="sites"
    typeSelection="decouvrir/sites"
    :autoBounds="true"
  />
</template>

<script>
import { mapGetters } from 'vuex';
import PageMap from '@/components/pages/PageMap.vue';

export default {
  components: {
    PageMap,
  },
  computed: {
    ...mapGetters('decouvrir', ['getItemsOfCurrentLang']),
    sites() {
      return this.getItemsOfCurrentLang('sites');
    },
  },
};
</script>
