<template>
  <div class="carousel-container">
    <div class="swiper-container" ref="carousel">
      <div class="swiper-wrapper" ref="slideContainer">
        <slot></slot>
      </div>
    </div>

    <div class="controls" v-if="(this.count > 4)">
      <button class="circle-btn medium outline" @click="this.previousSlide"><i class="icon icon-chevron-left"></i></button>
      <button class="circle-btn medium outline" @click="this.nextSlide"><i class="icon icon-chevron-right"></i></button>
    </div>
  </div>
</template>

<script>

import Swiper from 'swiper';
import 'swiper/swiper.scss';

export default {
  components: {
  },
  data() {
    return {
      activeIndex: false,
      swiper: null,
      options: {
        slidesPerView: 'auto',
      },
      count: 0,
    };
  },
  mounted() {
    this.swiper = new Swiper(this.$refs.carousel, this.options);
    this.count = this.$refs.slideContainer.children.length;
  },
  methods: {
    previousSlide() {
      this.swiper.slidePrev();
    },
    nextSlide() {
      this.swiper.slideNext();
    },
    refresh() {
      this.swiper.update();
    },
  },
};
</script>

<style lang="scss" scoped>
.carousel-container {
  position: relative;
  margin-left: -90px;

  .controls {
    display: flex;
    justify-content: flex-end;
    margin-top: 35px;

    > * {
      margin-left: 15px;
    }
  }
}

$slide-width: 410px;

.swiper-container.swiper-container {
  overflow: visible;
}

.swiper-slide {
  width: 405px;
  padding: 0 10px;

  ::v-deep .card-container {
    height: 100%;
    min-height: 420px;
  }
}

.carousel {
  width: $slide-width * 4;

  .frame {
    position: relative;
    width: 100%;
    font-size: 0;
    line-height: 0;
    white-space: nowrap;
  }

  .slides {
    display: inline-flex;
    align-items: stretch;
    pointer-events: none;
  }

  .slide {
    display: inline-block;
    position: relative;
    flex: 0 0 $slide-width;
    width: $slide-width;
    padding: 0 10px;
    transition: opacity 0.5s ease;
    // opacity: 0.7;
    line-height: 1.3;
    white-space: initial;
    pointer-events: all;

    // &.active {
    //   opacity: 1;

    //   ~ .slide {
    //     opacity: 1;
    //   }
    // }

    > * {
      height: 100%;
      min-height: 420px;
    }
  }
}
</style>
