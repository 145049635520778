import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist';
import createLogger from 'vuex/dist/logger';
import moment from 'moment';
import WordpressAjax from '@/wordpress-ajax';
import weather from './modules/weather';
import selection from './modules/selection';
import decouvrir from './modules/decouvrir';
import activites from './modules/activites';
import agenda from './modules/agenda';
import gourmande from './modules/gourmande';
import region from './modules/region';
import {
  ENABLE_A11Y,
  DISABLE_A11Y,
  SET_LAST_UPDATE,
} from './mutation-types';

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  // modules: [
  //   'selection',
  //   // 'agenda',
  // ],
});

export default new Vuex.Store({
  modules: {
    weather,
    selection,
    decouvrir,
    activites,
    agenda,
    gourmande,
    region,
  },
  state: {
    isA11yActivate: false,
    lastUpdate: undefined,
  },
  mutations: {
    [ENABLE_A11Y](state) {
      state.isA11yActivate = true;
    },
    [DISABLE_A11Y](state) {
      state.isA11yActivate = false;
    },
    [SET_LAST_UPDATE](state) {
      state.lastUpdate = Date.now();
    },
  },
  getters: {
    needToUpdate: (state) => {
      if (!state.lastUpdate) {
        return true;
      }
      const nowMoment = moment();
      const lastUpdateMoment = moment(state.lastUpdate);
      const isLastUpdateToday = nowMoment.isSame(lastUpdateMoment, 'day');

      return !isLastUpdateToday;
    },
  },
  actions: {
    toggleA11y({ state, commit }) {
      if (state.isA11yActivate) {
        commit(DISABLE_A11Y);
      } else {
        commit(ENABLE_A11Y);
      }
    },
    updateAllData({ commit, dispatch }) {
      return WordpressAjax.get('create_all_images')
        .then(() => {
          this.status = 'Mise à jour des contenus';

          return Promise.all([
            dispatch('weather/updateFromAPI'),
            dispatch('agenda/updateFromAPI'),
            dispatch('decouvrir/updateFromAPI'),
            dispatch('gourmande/updateFromAPI'),
            dispatch('activites/updateFromAPI'),
            dispatch('region/updateFromAPI'),
          ]);
        })
        .then(() => {
          commit(SET_LAST_UPDATE);
        });
    },
    resetAppData({ dispatch }) {
      dispatch('selection/resetSelection');
    },
  },
  strict: debug,
  plugins: debug ? [createLogger(), vuexLocal.plugin] : [vuexLocal.plugin],
});
