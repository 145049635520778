<template>
  <Modal :visible="visible" ref="modal" class="warning-modal">
    <div class="content">
      <span class="info-icon">
        <i class="icon" :class="`icon-${icon}`"></i>
      </span>
      <p class="message" v-html="message"></p>
    </div>
  </Modal>
</template>

<script>
import Modal from '@/components/modals/Modal';

export default {
  data() {
    return {
      visible: false,
    };
  },
  components: {
    Modal,
  },
  props: {
    message: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      default: 'info',
    },
  },
  methods: {
    open() {
      this.$refs.modal.open();
    },
    onSubmit() {
      this.$refs.modal.close();
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .modal {
  width: 72%;
  padding-top: 200px;
  padding-bottom: 200px;
}

.content {
  display: flex;
  align-items: center;
}

.message {
  margin: 0;
  font-size: 5rem;
  font-weight: $book-weight;
  line-height: 1.5;
}

.info-icon {
  display: inline-block;
  flex: 0 0 auto;
  width: 80px;
  height: 80px;
  margin-right: 60px;
  padding-left: 2px;
  border: 3px solid $orange;
  border-radius: 50%;
  color: $orange;
  line-height: 77px;
  text-align: center;

  .icon {
    font-size: 4rem;
  }
}

.btn-container {
  text-align: right;

  .btn {
    min-width: 300px;
  }
}
</style>
