<template>
  <div class="map-page" v-if="visibleLocations.length > 0">
    <div class="map-container">
      <slot></slot>
      <MapControls
        @zoomIn="zoomIn"
        @zoomOut="zoomOut"
        @recenter="recenter"
      />

      <MglMap
        :accessToken="accessToken"
        :mapStyle="mapStyle"
        :center="center"
        :zoom="zoom"
        :refreshExpiredTiles="false"
        :minZoom="minZoom"
        :maxZoom="maxZoom"
        @load="onMapLoad"
        @zoomstart="handleMapZoomStart"
        @zoom="handleMapZoom"
        @zoomend="handleMapZoomEnd"
        @movestart="handleMapMoveStart"
        @moveend="handleMapMoveEnd"
        @click="onMapClick"
        ref="map"
      >
        <ClusteredMarkers
          ref="markers"
          :locations="visibleLocations"
          :zoom="currentZoom"
          @clusterClick="onClusterClick"
          @markerClick="onMarkerClick"
          v-if="currentZoom"
        />
      </MglMap>

      <transition name="popup-fade">
        <MapPopupCard
          v-if="currentLocation"
          :key="currentLocation.id"
          :imageUrl="currentLocation.image && currentLocation.image.small ? currentLocation.image.small : false"
          :title="currentLocation.titre"
          :localisation="fullDescription ? false : getAdresse(currentLocation)"
          :description="getDescription(currentLocation)"
          :url="fullDescription ? false : `/${typeSelection}/spot/${currentLocation.id}`"
          :typeSelection="typeSelection"
          :itemSelection="currentLocation"
          @close="hidePopin"
        />
      </transition>
    </div>

    <LocationCarousel
      ref="carousel"
    >
      <div
        class="swiper-slide"
        v-for="(location, index) in visibleLocations"
        :key="index"
      >
        <LocationCard
          :imageUrl="location.image && location.image.small ? location.image.small : false"
          :title="location.titre"
          :typeSelection="typeSelection"
          :itemSelection="location"
          @cardClick="showOnMap(location.id)"
        />
      </div>
    </LocationCarousel>
  </div>
</template>

<script>
import Mapbox from 'mapbox-gl';
import { MglMap } from 'vue-mapbox';
import { mapState } from 'vuex';
import config from '@/config';
import utils from '@/utils';
import mapUtils from '@/mapUtils';
import ClusteredMarkers from '@/components/ClusteredMarkers.vue';
import MapControls from '@/components/MapControls.vue';
import LocationCarousel from '@/components/LocationCarousel.vue';
import MapPopupCard from '@/components/cards/MapPopupCard';
import LocationCard from '@/components/cards/LocationCard.vue';

export default {
  components: {
    MglMap,
    ClusteredMarkers,
    MapControls,
    LocationCarousel,
    LocationCard,
    MapPopupCard,
    // MglGeojsonLayer,
  },
  data() {
    return {
      accessToken: config.MAPBOX.ACCESS_TOKEN,
      mapStyle: config.MAPBOX.MAP_STYLE,
      selectedLocationId: null,
      center: mapUtils.defaultCenter,
      zoom: mapUtils.defaultZoom,
      minZoom: mapUtils.minZoom,
      maxZoom: mapUtils.maxZoom,
      currentZoom: false,
      cityCenterBounds: mapUtils.cityCenterBounds,
      mapMoving: false,
      isActivated: true,
      outsideCentering: false,
    };
  },
  props: {
    fullDescription: {
      type: Boolean,
      required: false,
    },
    locations: {
      type: Array,
      required: true,
    },
    autoBounds: {
      type: Boolean,
      default: false,
    },
    typeSelection: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapState(['isA11yActivate']),
    mapPadding() {
      return this.isA11yActivate ? mapUtils.mapPaddingA11y : mapUtils.mapPadding;
    },
    visibleLocations() {
      return this.locations.filter(location => location.localisation && location.localisation.lat && location.localisation.lng);
    },
    currentLocation() {
      return this.visibleLocations.find(location => location.id === this.selectedLocationId);
    },
    clusteredMarkers() {
      return this.$refs.markers;
    },
    locationBounds() {
      return mapUtils.getBounds(this.visibleLocations);
    },
    maxBounds() {
      const bounds = new Mapbox.LngLatBounds();
      bounds.extend(this.cityCenterBounds);
      bounds.extend(this.locationBounds);
      return bounds;
    },
  },
  methods: {
    onMapLoad({ map, component }) {
      this.mapAsyncActions = component.actions;

      map.resize();
      map.dragRotate.disable();
      map.touchZoomRotate.disableRotation();

      this.currentZoom = map.getZoom();

      this.recenter();

      utils.wait(300)
        .then(() => this.$root.$emit('fully-loaded', this.$route.path));
    },
    zoomIn() {
      if (this.mapMoving) {
        return;
      }

      this.mapAsyncActions.zoomIn();
    },
    zoomOut() {
      if (this.mapMoving) {
        return;
      }

      this.mapAsyncActions.zoomOut();
    },
    recenter() {
      if (this.autoBounds) {
        this.fitMapToBounds(this.maxBounds);
      } else {
        this.fitMapToBounds(this.cityCenterBounds);
      }
    },
    handleMapMoveStart() {
      this.hidePopin();
    },
    handleMapMoveEnd({ map }) {
      if (!this.isActivated || this.currentLocation || this.mapMoving) {
        return;
      }

      const newBounds = mapUtils.areBoundsInBounds(this.maxBounds, map.getBounds());

      if (!this.outsideCentering && newBounds) {
        this.outsideCentering = true;

        this.fitMapToBounds(newBounds)
          .then(() => {
            this.outsideCentering = false;
          });
      }
    },
    handleMapZoomStart() {
      this.mapMoving = true;
      this.hidePopin();
    },
    handleMapZoomEnd() {
      this.mapMoving = false;
    },
    handleMapZoom({ map }) {
      const newZoom = Math.round(map.getZoom() * 100) / 100;

      if (newZoom !== this.currentZoom) {
        this.currentZoom = newZoom;
      }
    },
    onMapClick() {
      this.hidePopin();
    },
    onClusterClick(clusterBounds) {
      return this.fitMapToBounds(clusterBounds);
    },
    onMarkerClick(markerId) {
      this.selectMarker(markerId);
    },
    showPopin(spotId) {
      if (this.selectedLocationId === spotId) {
        return;
      }

      this.selectedLocationId = spotId;
    },
    showOnMap(spotId) {
      if (this.currentLocation && this.currentLocation.id === spotId) {
        return;
      }

      const isClustered = this.clusteredMarkers.isClustered(spotId);

      if (!isClustered) {
        this.selectMarker(spotId);
      } else {
        this.selectMarker(spotId, this.clusteredMarkers.maxZoom);
      }
    },
    hidePopin() {
      this.selectedLocationId = null;
    },
    getAdresse(value) {
      return utils.getAdresse(value);
    },
    selectMarker(spotId, zoom = false) {
      if (this.currentLocation && this.currentLocation.id === spotId) {
        return;
      }

      this.hidePopin();
      this.mapMoving = true;

      const location = this.locations.find(loc => loc.id === spotId);

      const options = {
        center: [location.localisation.lng, location.localisation.lat],
        offset: [-150, 60],
        padding: this.mapPadding,
      };

      if (zoom) {
        options.zoom = zoom;
      }

      const panning = this.mapAsyncActions.easeTo(options);

      panning
        .then(() => {
          this.showPopin(location.id);
          this.mapMoving = false;
        });
    },
    fitMapToBounds(bounds, padding = this.mapPadding) {
      if (!this.mapAsyncActions || !bounds) {
        return Promise.resolve(false);
      }

      this.mapMoving = true;

      return this.mapAsyncActions.fitBounds(bounds, { linear: true, padding })
        .then(() => {
          this.mapMoving = false;
        });
    },
    getDescription(item) {
      if (this.fullDescription) {
        return item.description;
      }

      if (item.description_commercial) {
        return `<p>${item.description_commercial}</p>`;
      }

      return item.description_courte;
    },
  },
  created() {
    this.mapbox = Mapbox;
  },
  activated() {
    this.isActivated = true;

    // If map already loaded
    if (this.mapAsyncActions) {
      // this.$refs.map.map.resize();
      this.$refs.map.map.resize();
      this.recenter();
    }

    // if (this.$route.query.spotId) {
    //   this.showOnMap(this.$route.query.spotId);
    // }
  },
  deactivated() {
    this.isActivated = false;
    this.selectedLocationId = null;
    this.recenter();
  },
  watch: {
    isA11yActivate() {
      if (this.isActivated) {
        this.$refs.map.map.resize();
        this.recenter();
      }
    },
    locations() {
      this.$nextTick(() => {
        this.$refs.carousel.refresh();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.popup-fade-enter-active,
.popup-fade-leave-active {
  transition: opacity 0.17s ease;
}

.popup-fade-enter {
  opacity: 0;
}

.popup-fade-leave-to {
  opacity: 0;
}
</style>
