<template>
  <div class="deco-card" :style="{ backgroundImage: `url(${image})` }">
    <div class="title">
      <i class="icon" :class="`icon-${surtitleIcon}`"></i>
      <span>{{ surtitle }}</span>
    </div>
    <div class="rte-content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    surtitleIcon: String,
    surtitle: String,
    image: String,
  },
};
</script>

<style lang="scss" scoped>
.deco-card {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 670px;
  padding: 40px 0 70px;
  border-radius: 20px;
  background-color: $black;
  background-repeat: no-repeat;
  background-size: cover;
  color: $white;
}

.title {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: auto;
  margin-bottom: 70px;
  padding: 15px 30px;
  border-radius: 30px;
  color: $white;
  font-size: 2.2rem;
  text-align: center;

  .icon {
    margin-bottom: 10px;
    color: $pink;
    font-size: 4rem;
  }
}

.rte-content {
  width: 80%;
  margin-bottom: auto;
  font-size: 3.4rem;
  text-align: center;

  ::v-deep .medium-title {
    font-size: 3.6rem;
    font-weight: $bold-weight;
  }

  img {
    margin-bottom: 55px;
  }

  p {
    font-weight: $book-weight;
  }
}
</style>
