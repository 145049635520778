<template>
  <div class="selection-card">
    <div class="content" @click="goToItem">
      <div class="image-container">
        <img :src="item.image ? item.image : require('@/assets/images/no-img.jpg')" alt="">
      </div>

      <div class="infos">
        <div class="date" v-if="item.date">{{ item.date }}</div>
        <div class="titre">{{ item.titre }}</div>
        <div class="description" v-if="item.description">{{ item.description }}</div>
      </div>
    </div>

    <button @click="toggleToSelection(typeSelection, item)" class="remove">
      <div class="circle-box">
        <div class="circle"></div>
        <div class="line"></div>
      </div>
      {{$t('selection.modal.remove')}}
    </button>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  props: {
    typeSelection: String,
    item: Object,
  },
  methods: {
    ...mapActions('selection', ['toggleItem']),
    toggleToSelection(type, item) {
      this.toggleItem({ type, item, id: item.id });
    },
    goToItem() {
      this.$emit('navigate');
      this.$router.push(this.item.link);
    },
  },
};
</script>

<style lang="scss" scoped>
$remove-width: 200px;

.selection-card {
  position: relative;
  z-index: 1;
  width: 100%;
  height: 320px;
  margin-bottom: 30px;
  padding: 20px;
  border: 6px solid $white-alt;
  border-radius: 40px;
  background-color: $white;

  .content {
    display: flex;
    align-items: center;
    height: 100%;
    padding-right: $remove-width;
    color: $black;
    text-decoration: none;
  }

  .image-container {
    flex: 0 0 auto;
    width: 410px;
    height: 100%;
    margin-right: 70px;
    overflow: hidden;
    border-radius: 40px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center center;
    }
  }

  .infos {
    flex: 1 1 auto;
    padding-right: 40px;
  }

  .remove {
    display: flex;
    position: absolute;
    z-index: 2;
    top: 0;
    right: 0;
    bottom: 0;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: $remove-width;
    height: 100%;
    padding: 0;
    border: none;
    background: transparent;
    color: $pink;
    font-size: 2.5rem;
    font-weight: $regular-weight;

    &::before {
      content: "";
      position: absolute;
      top: 20px;
      bottom: 20px;
      left: 0;
      width: 6px;
      background-color: $white-alt;
    }

    .circle-box {
      display: inline-block;
      position: relative;
      width: 70px;
      height: 70px;
      margin-bottom: 20px;

      .circle {
        width: 70px;
        height: 70px;
        border: 3px solid $pink;
        border-radius: 50%;
      }

      .line {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 30px;
        height: 3px;
        transform: translate(-50%, -50%);
        background-color: $pink;
      }
    }
  }
}

.date {
  margin-bottom: 20px;
  color: $pink;
  font-size: 2rem;
  text-transform: uppercase;
}

.titre {
  margin: 0;
  color: $black;
  font-size: 3.4rem;
  font-weight: $bold-weight;
}

.description {
  margin-top: 15px;
  font-size: 3rem;
  font-weight: $book-weight;
  line-height: 1.4;
}
</style>
