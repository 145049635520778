<template>
  <div class="agenda">
    <p class="warning" v-if="$i18n.locale !== 'fr'"><span class="info-icon"><i class="icon icon-info"></i></span> <span v-html="$t('agenda.not-available')"></span></p>

    <div class="agenda-intro">
      <p class="agenda-title" v-html="$tc('agenda.comingEvents', filteredEvents.length)"></p>
      <button class="btn outline" @click="resetFilters" v-if="hasFilters">
        <i class="icon icon-reset"></i>
        {{$t('agenda.filter.reset')}}
      </button>
      <button class="btn turquoise" @click="showFilterModal">
        <i class="icon icon-filter"></i>
        {{$t('agenda.filter-title')}}
      </button>
    </div>

    <div class="filters" v-if="hasFilters">
      <div class="filter" v-if="filterValues.when !== 'all'" @click="resetWho">{{filterLabels.when[filterValues.when]}} <i class="icon icon-cross"></i></div>
      <div class="filter" v-for="catID in filterValues.categories" :key="catID" @click="removeFilter(catID, 'categories')">{{filterLabels.categories[catID]}} <i class="icon icon-cross"></i></div>
      <div class="filter" v-for="whoID in filterValues.who" :key="whoID" @click="removeFilter(whoID, 'who')">{{filterLabels.who[whoID]}} <i class="icon icon-cross"></i></div>
    </div>

    <PageEventsListing
      :events="filteredEvents"
      typeSelection="agenda/agenda"
      linkPath="/agenda/agenda/event/"
    >
      <template v-slot:filter-btn>
        <button class="btn turquoise large" @click="showFilterModal">
          <i class="icon icon-filter"></i>
          {{$t('agenda.filter-title')}}
        </button>
      </template>
    </PageEventsListing>

    <AgendaFilterModal @filterUpdate="filterUpdate" ref="agendaModal" :labels="filterLabels"/>

    <WarningModal
      ref="langModal"
      class="warning-modal"
      :message="$t('agenda.not-available')"
    />
  </div>
</template>

<script>
import Vue from 'vue';
import { mapState } from 'vuex';
import PageEventsListing from '@/components/pages/PageEventsListing';
import AgendaFilterModal from '@/components/modals/AgendaFilterModal';
import WarningModal from '@/components/modals/WarningModal';
import utils from '@/utils';

export default {
  components: {
    PageEventsListing,
    AgendaFilterModal,
    WarningModal,
  },
  data() {
    return {
      scrollAmount: 0,
      filterValues: {
        when: 'all',
        categories: [],
        who: [],
      },
    };
  },
  computed: {
    ...mapState('agenda', ['categories', 'events']),
    filterLabels() {
      const categories = this.categories;

      return {
        when: {
          all: this.$t('agenda.filter.when.all'),
          today: this.$t('agenda.filter.when.today'),
          week: this.$t('agenda.filter.when.week'),
        },
        who: {
          'moins de 7 ans': this.$t('agenda.filter.who.moins de 7 ans'),
          '8 - 12 ans': this.$t('agenda.filter.who.8 - 12 ans'),
          'plus de 12 ans': this.$t('agenda.filter.who.plus de 12 ans'),
        },
        categories,
      };
    },
    filteredEvents() {
      return this.events.filter(event => this.isVisibleWithFilter(event));
    },
    noScroll() {
      return this.filteredEvents.length <= 9;
    },
    hasFilters() {
      return this.filterValues.when !== 'all'
        || this.filterValues.categories.length > 0
        || this.filterValues.who.length > 0;
    },
    isScrolled() {
      return this.scrollAmount > 500 || this.noScroll;
    },
  },
  methods: {
    resetFilters() {
      this.filterValues = {
        when: 'all',
        categories: [],
        who: [],
      };
    },
    resetWho() {
      this.$set(this.filterValues, 'when', 'all');
    },
    removeFilter(id, type) {
      Vue.delete(this.filterValues[type], this.filterValues[type].indexOf(id));
    },
    isVisibleWithFilter(event) {
      const isVisible = true;

      switch (this.filterValues.when) {
        case 'today':
          if (!utils.isDateToday(event.nextDate)) {
            return false;
          }
          break;
        case 'week':
          if (!utils.isDateInWeek(event.nextDate)) {
            return false;
          }
          break;
        default:
      }

      if (this.filterValues.categories.length > 0) {
        if (!event.categorie || event.categorie.length <= 0) {
          return false;
        }
        // Filtre catégorie avec OU
        // Pour ET remplacer le false par true et le || par &&
        let matchOneCategory = false;

        this.filterValues.categories.forEach((filterCat) => {
          matchOneCategory = matchOneCategory || event.categories.indexOf(filterCat) !== -1;
        });

        if (!matchOneCategory) {
          return false;
        }
      }

      if (this.filterValues.who.length > 0) {
        if (!event.tranchesAge || event.tranchesAge.length <= 0) {
          return false;
        }

        // Filtre catégorie avec OU
        // Pour ET remplacer le false par true et le || par &&
        let matchOneAge = false;

        this.filterValues.who.forEach((filterAge) => {
          matchOneAge = matchOneAge || event.tranchesAge.indexOf(filterAge) !== -1;
        });

        if (!matchOneAge) {
          return false;
        }
      }

      return isVisible;
    },
    showFilterModal() {
      this.$refs.agendaModal.open(this.filterValues);
    },
    filterUpdate(newFilters) {
      this.filterValues = newFilters;
    },
    onScroll(event) {
      this.scrollAmount = event.currentTarget.scrollTop;
    },
    handleLocaleChange(locale) {
      if (locale !== 'fr') {
        this.$refs.langModal.open();
      }
    },
  },
  activated() {
    this.handleLocaleChange(this.$i18n.locale);
  },
  watch: {
    '$i18n.locale'(newValue) {
      this.handleLocaleChange(newValue);
    },
  },
};
</script>

<style lang="scss" scoped>
.agenda {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
}

.agenda-intro {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;

  .btn {
    margin-left: 15px;
  }
}

.agenda-title {
  margin-right: auto;
  margin-bottom: 0;
  color: $grey;
  font-size: 5.5rem;
  font-weight: $book-weight;

  ::v-deep strong {
    color: $black;
    font-weight: $book-weight;
  }
}

.events-container {
  display: grid;
  grid-auto-rows: max-content;
  grid-gap: 40px;
  grid-template-columns: repeat(3, 1fr);
  min-height: 100%;
  overflow: auto;
}

.filters {
  display: flex;
  flex-wrap: wrap;

  .filter {
    display: block;
    margin-right: 15px;
    margin-bottom: 15px;
    padding: 25px 40px;
    transition: background-color $transition-duration ease, color $transition-duration ease;
    border-radius: 90px;
    background-color: $turquoise;
    color: $white;
    font-size: 2.4rem;
    line-height: 1.1;
    text-transform: uppercase;

    .icon {
      margin-left: 18px;
    }
  }
}

.no-result {
  margin-top: $spacing;
  font-size: 3.4rem;
  text-align: center;

  .btn {
    margin-top: 70px;
  }
}

.btn-container {
  position: absolute;
  z-index: 10;
  right: 0;
  bottom: 550px;
  left: 0;
  text-align: center;

  &.scrolled {
    .scroll-btn {
      transform: translate(-50%, -100px);
      opacity: 0;
      pointer-events: none;
    }
  }

  &:not(.scrolled) {
    .btn {
      transform: translateY(100px);
      opacity: 0;
      pointer-events: none;
    }
  }

  .btn {
    transition: opacity $transition-duration ease, transform $transition-duration ease;
    box-shadow: 0 4px 34px rgba($true-black, 0.25);
  }
}

.scroll-btn {
  position: absolute;
  z-index: 0;
  top: 0;
  left: 50%;
  width: 300px;
  height: 300px;
  transform: translateX(-50%);
  transition: opacity $transition-duration ease, transform $transition-duration ease;
  border-radius: 50%;
  background-color: $white;
  box-shadow: 0 4px 34px rgba($true-black, 0.15);

  .icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: $pink;
    font-size: 13rem;
  }

  .icon-scroll-hand {
    animation: scroll-animate 1.6s infinite ease;
  }

  @keyframes scroll-animate {
    0%,
    100% {
      transform: translate(-50%, -60%);
    }

    30% {
      transform: translate(-50%, -10%);
    }
  }
}

.scroll-area {
  &::after {
    content: '';
    display: block;
    position: absolute;
    z-index: 5;
    right: 0;
    bottom: -1px;
    left: 0;
    height: 800px;
    background: linear-gradient(to top, $white-alt 0%, rgba($white-alt, 0) 100%);
    pointer-events: none;

    .a11y & {
      height: 500px;
    }
  }

  .inner-scroll-area {
    padding-bottom: 800px;
  }
}

.a11y {
  .agenda {
    .btn-container {
      bottom: 450px;

      .scroll-btn {
        width: 200px;
        height: 200px;

        .icon {
          font-size: 8rem;
        }
      }

      &.scrolled {
        .btn {
          transform: translateY(100px);
          opacity: 0;
          pointer-events: none;
        }
      }
    }
  }
}
</style>
