<template>
  <div class="second-nav" v-if="links.length > 0">
    <router-link
      class="link"
      :to="getRoute(link)"
      v-for="link in links"
      :key="link.path"
    >{{$t(`${link.meta.title}.title`)}}</router-link>
  </div>
</template>

<script>
export default {
  computed: {
    rootRoutes() {
      return this.$router.options.routes;
    },
    baseRoute() {
      return this.rootRoutes.find(route => route.path === this.$route.matched[0].path);
    },
    links() {
      if (!this.baseRoute || !this.baseRoute.children) {
        return false;
      }

      return this.baseRoute.children.filter(route => route.component && (route.meta));
    },
  },
  methods: {
    getRoute(link) {
      return `${this.baseRoute.path}/${link.path}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.second-nav {
  display: flex;

  &.gourmande {
    margin-left: 80px;
  }
}

.link {
  display: block;
  margin-right: 10px;
  margin-bottom: 0;
  padding: 30px 35px;
  transition: background-color $transition-duration ease, color $transition-duration ease;
  border-radius: 90px;
  background-color: $white;
  color: $grey;
  font-size: 2.2rem;
  line-height: 1.1;
  text-decoration: none;
  text-transform: uppercase;

  &.router-link-active {
    background-color: $turquoise;
    color: $white;
  }
}
</style>
