<template>
  <div class="wrapper-card horizontal-card">
    <div class="card-container">
      <div class="card-top">
        <img :src="imageUrl ? imageUrl : require('@/assets/images/no-img.jpg')" class="image" alt="">
        <span class="shape"></span>
      </div>
      <div class="card-content">
        <div class="card-content-header">
          <i class="icon" :class="`icon-${surtitleIcon}`"></i>
          {{ surtitle }}
        </div>
        <div class="card-fav">
          <SelectionButton :type="typeSelection" :item="itemSelection" :big="true" />
        </div>
        <div class="title">{{ title}}</div>
        <div class="rte-content" v-html="description"></div>
      </div>
    </div>
  </div>
</template>

<script>
import SelectionButton from '@/components/SelectionButton';

export default {
  components: {
    SelectionButton,
  },
  props: {
    imageUrl: {},
    title: String,
    surtitleIcon: String,
    surtitle: String,
    description: String,
    typeSelection: String,
    itemSelection: Object,
  },
};
</script>

<style lang="scss" scoped>
.card-container {
  flex-direction: row;

  .card-top {
    position: relative;
    width: 38%;
    height: auto;

    .shape {
      display: block;
      position: absolute;
      top: -1px;
      right: -1px;
      left: auto;
      width: 74px;
      height: calc(100% + 2px);
      clip-path: url(#cardVerticalMask);
    }
  }

  .card-fav {
    top: 60px;
    right: 60px;
  }
}

.card-content-header {
  margin-bottom: 30px;
  color: $pink;
  font-size: 2.6rem;
  text-transform: uppercase;

  .icon {
    margin-right: 10px;
    font-size: 3.5rem;
  }
}

.title {
  margin-bottom: 30px;
  color: $black;
  font-size: 6rem;
}

.card-content {
  width: 62%;
  padding: 90px 80px;
}

.rte-content {
  ::v-deep p {
    font-size: 2.8rem;
    font-weight: $book-weight;

    strong {
      font-weight: $bold-weight;
    }
  }
}
</style>
