<template>
  <div class="meteo" v-if="isSet">
    <div class="meteo-bloc meteo-bloc-today">
      <p class="meteo-txt">{{ $t('weather.today') }}</p>
      <div class="meteo-weather">
        <MeteoIcon :icon="today.icon"/>
        <div v-if="$i18n.locale === 'en'" class="meteo-temper">
          {{(today.temperature * 9/5) + 32}}°F
        </div>
        <div v-else class="meteo-temper">
          {{today.temperature}}°C
        </div>
      </div>
    </div>

    <div class="meteo-bloc meteo-bloc-tomorrow">
      <p class="meteo-txt">{{tomorrowLabel}}</p>
      <div class="meteo-weather">
        <MeteoIcon :icon="tomorrow.icon"/>
        <div v-if="$i18n.locale === 'en'" class="meteo-temper">
          {{(tomorrow.temperature * 9/5) + 32}}°F
        </div>
        <div v-else class="meteo-temper">
          {{tomorrow.temperature}}°C
        </div>
      </div>
    </div>

    <div class="meteo-bloc meteo-bloc-afterTomorrow">
      <p class="meteo-txt">{{afterTomorrowLabel}}</p>
      <div class="meteo-weather">
        <MeteoIcon :icon="afterTomorrow.icon"/>
        <div v-if="$i18n.locale === 'en'" class="meteo-temper">
          {{(afterTomorrow.temperature * 9/5) + 32}}°F
        </div>
        <div v-else class="meteo-temper">
          {{afterTomorrow.temperature}}°C
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import utils from '@/utils';
import MeteoIcon from './MeteoIcon.vue';

export default {
  components: {
    MeteoIcon,
  },
  computed: {
    ...mapState('weather', ['isSet', 'today', 'tomorrow', 'afterTomorrow']),
    tomorrowLabel() {
      return utils.dayLabel(1);
    },
    afterTomorrowLabel() {
      return utils.dayLabel(2);
    },
  },
};
</script>

<style lang="scss" scoped>
.meteo {
  display: flex;
  margin-top: 30px;
  margin-bottom: -30px;
  color: $grey;
  font-weight: $book-weight;
}

.meteo-bloc {
  position: relative;
  flex: 1 1 auto;
  margin-right: 70px;
  padding-right: 70px;
  border: none;

  &:last-child {
    &::after {
      display: none;
    }
  }

  &::after {
    content: "";
    position: absolute;
    z-index: 9;
    top: 0;
    right: 0;
    width: 1px;
    height: 100%;
    opacity: 0.25;
    background-color: currentColor;
  }
}

.meteo-bloc-afterTomorrow {
  margin-right: 0;
  padding-right: 0;
  border-right: 0;
}

.meteo-weather {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 0;
  margin-bottom: -30px;

  p {
    margin: 0;
    margin-bottom: 5px;
  }
}

.meteo-txt {
  margin: 0;
  font-size: 3.6rem;
  text-align: center;
  text-transform: capitalize;
}

.meteo-icon {
  width: 110px;
}

.meteo-temper {
  font-size: 4.6rem;
  font-weight: $light-weight;
  line-height: 1.2;
}
</style>
