import Mapbox from 'mapbox-gl';

export default {
  maxZoom: 20,
  minZoom: 8,
  defaultZoom: 15,
  defaultCenter: [
    1.4407069039490352,
    43.601446763328,
  ],
  cityCenterBounds: new Mapbox.LngLatBounds(
    new Mapbox.LngLat(1.4266, 43.5904),
    new Mapbox.LngLat(1.4569, 43.6120),
  ),
  mapPadding: {
    top: 150,
    right: 150,
    bottom: 150,
    left: 250,
  },
  mapPaddingA11y: {
    top: 120,
    right: 150,
    bottom: 70,
    left: 250,
  },
  getBounds(points) {
    const bounds = new Mapbox.LngLatBounds();

    points.forEach((point) => {
      bounds.extend(this.getRoundedLatLng(point.localisation));
    });

    return bounds;
  },
  getRoundedLatLng(localisation) {
    return [
      this.roundCoordinate(localisation.lng),
      this.roundCoordinate(localisation.lat),
    ];
  },
  roundCoordinate(value) {
    const ratio = 10 ** 11;
    return Math.round(value * ratio) / ratio;
  },
  areBoundsInBounds(maxBounds, bounds) {
    const newBounds = new Mapbox.LngLatBounds([
      Math.max(maxBounds.getWest(), bounds.getWest()),
      Math.max(maxBounds.getSouth(), bounds.getSouth()),
    ], [
      Math.min(maxBounds.getEast(), bounds.getEast()),
      Math.min(maxBounds.getNorth(), bounds.getNorth()),
    ]);

    if (newBounds.toString() === bounds.toString()) {
      return false;
    }

    return newBounds;
  },
};
