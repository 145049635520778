import utils from '@/utils';
import WordpressAjax from '@/wordpress-ajax';
import { UPDATE_EVENTS, UPDATE_CATEGORIES } from '@/store/mutation-types';
// import events from '@/assets/tempData/agenda.json';

export default {
  namespaced: true,
  state: {
    events: [],
    categories: {},
  },
  mutations: {
    [UPDATE_EVENTS](state, events) {
      const newEvents = events;

      newEvents.forEach((event, index) => {
        const nextDate = utils.getNextDate(event.dates);

        newEvents[index].nextDate = nextDate;
        newEvents[index].nextDate.formatted = {
          date: utils.formatDate(nextDate),
          dateLong: utils.formatDate(nextDate, 'long'),
          horaire: utils.formatHoraire(nextDate),
        };

        newEvents[index].categories = event.categorie.map(cat => utils.generateID(cat));
        newEvents[index].displayCategorie = newEvents[index].categorie.join(', ');
      });

      newEvents.sort((eventA, eventB) => utils.getMomentDate(eventA.nextDate.dateFin).diff(utils.getMomentDate(eventB.nextDate.dateFin)));

      state.events = newEvents;
    },
    [UPDATE_CATEGORIES](state, events) {
      const categories = {};

      events.forEach((event) => {
        event.categorie.forEach((cat) => {
          if (cat !== '') {
            categories[utils.generateID(cat)] = cat;
          }
        });
      });

      state.categories = categories;
    },
  },
  getters: {
    getEventById: state => id => state.events.find(event => event.id === id),
  },
  actions: {
    updateFromAPI(context) {
      return WordpressAjax.get('get_agenda')
        .then((res) => {
          context.commit(UPDATE_EVENTS, res);
          context.commit(UPDATE_CATEGORIES, res);
        });
    },
  },
};
