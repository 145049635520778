<template>
  <div class="quartier-card" :style="{ backgroundImage: image ? `url(${image})` : false, }" @click="$emit('cardClick')">
    <div class="count">
      <i class="icon icon-map-pin" :style="{ color }"></i>
      <span class="count-label">{{count}}</span>
    </div>
    <div class="title">{{title}}</div>
  </div>
</template>

<script>
export default {
  props: {
    image: String,
    title: String,
    color: String,
    count: Number,
  },
};
</script>

<style lang="scss" scoped>
.quartier-card {
  display: flex;
  position: relative;
  z-index: 1;
  flex-direction: column;
  width: 100%;
  min-height: 450px;
  padding: 20px 20px 40px 25px;
  overflow: hidden;
  border-radius: 20px;
  background-color: $black;
  background-image: url('~@/assets/images/no-img.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  color: $white;

  &::before {
    content: '';
    display: block;
    position: absolute;
    z-index: -1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: linear-gradient(to top, rgba($true-black, 0.8) 0%, rgba(0, 0, 0, 0) 100%);
  }

  .count {
    margin-top: 0;
    margin-left: auto;
  }

  .title {
    margin-top: auto;
  }
}

.title {
  margin: 0;
  font-size: 3.5rem;
  font-weight: $book-weight;
}

.count {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;

  .icon {
    display: block;
    font-size: 6.5rem;
  }

  .count-label {
    position: absolute;
    z-index: 2;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: $white;
    font-size: 2.6rem;
    font-weight: $regular-weight;
    line-height: 1.2;
    text-align: center;
  }
}
</style>
