<template>
  <div class="home-animation" :style="{ maxWidth: `${size}px`, maHeight: `${size}px` }">
    <video
      :src="require(`@/assets/videos/${id}.webm`)"
      muted="true"
      :poster="require(`@/assets/videos/${id}.png`)"
      loop="loop"
      ref="video"
    />
  </div>
</template>

<script>
export default {
  props: {
    id: String,
    size: Number,
  },
  data() {
    return {
      promise: false,
    };
  },
  methods: {
    play() {
      this.promise = this.$refs.video.play()
        .catch((error) => {
          console.error(error);
        });
    },
    pause() {
      this.promise.then(() => {
        this.$refs.video.pause();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
video {
  width: 100%;
  height: 100%;
}
</style>
