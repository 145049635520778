
<template>
  <PageCards
    :cards="activites"
    typeSelection="activites/activites"
    descriptionField="description_commercial"
    linkPath="/activites/activites/spot/"
  >
    <!-- <template v-slot:first-card>
      <SimpleCard
        v-if="$i18n.locale === 'fr'"
        :imageUrl="require('@/assets/images/as-jungle.jpg')"
        title="Les As de la Jungle"
        description="Votre mission : explorer Toulouse en famille et retrouver Maurice, Junior, Miguel, Batricia et Gilbert. Votre équipement : une carte de la ville, des autocollants, un smartphone ou une tablette. À vous de jouer pour découvrir à chaque étape du parcours quel personnage se trouve tout près et la surprise en réalité augmentée qu'il vous a réservée..."
        :enVenteIci="true"
      />
    </template> -->
  </PageCards>
</template>

<script>
import { mapGetters } from 'vuex';
import PageCards from '@/components/pages/PageCards.vue';

export default {
  components: {
    PageCards,
  },
  computed: {
    ...mapGetters('activites', ['getItemsOfCurrentLang']),
    activites() {
      return this.getItemsOfCurrentLang('activites');
    },
  },
};
</script>
