<template>
  <Page
    :title="$route.params.parent"
  >
    <PageDetail
      :typeSelection="`${$route.params.parent}/${$route.params.type}`"
      :selectionItem="event"
      :title="event.titre"
      :enVenteIci="event.enVenteIci"
      :accesMobiliteReduite="event.accesMobiliteReduite"
      :includePassTourisme="event.includePassTourisme"
      :image="event.image && event.image.large ? event.image.large : false"
      v-if="event"
      lang="fr"
    >
      <template v-slot:date>{{ event.nextDate.formatted.dateLong }} <span class="small">{{ event.nextDate.formatted.horaire }}</span></template>
      <template v-slot:location>{{ event.lieu }}</template>
      <template v-slot:categories>{{ event.displayCategorie }}</template>

      <template v-slot:about>
        <div class="rte-content">
          <p v-html="nl2br(event.aPropos)"></p>
        </div>
      </template>

      <template v-slot:prices>
        <div v-for="tarif in event.tarifs" :key="tarif.label" class="bloc-tarif">
          <h4 class="card-title">{{ tarif.label }}</h4>
          <p v-if="tarif.min != ''" class="line-tarif line-tarif-min">
            <span class="label">{{$t('tarifs.min')}}</span>
            <span class="value">{{ tarif.min }} €</span>
          </p>
          <p v-if="tarif.max != ''" class="line-tarif line-tarif-max">
            <span class="label">{{$t('tarifs.max')}}</span>
            <span class="value">{{ tarif.max }} €</span>
          </p>
          <!-- <p v-if="tarif.infos != ''" class="line-tarif line-tarif-infos">
            <span class="label">{{$t('tarifs.extra')}}</span>
            <span class="value">{{ tarif.infos }}</span>
          </p> -->
          <p v-if="tarif.comp != ''" class="line-tarif line-tarif-infos">
            <span class="label">{{$t('tarifs.extra')}}</span>
            <span class="value">{{ tarif.comp }}</span>
          </p>
        </div>
      </template>

      <template v-slot:horaires v-if="event.dates.length > 0">
        <HoraireItem
          v-for="(date, index) in event.dates"
          :key="index"
          :horaire="date"
          :simple="true"
        />
      </template>

      <template v-slot:acces v-if="event.acces">
        <p>{{$t('metro-tram-station')}} {{ event.acces }}</p>
      </template>
    </PageDetail>
  </Page>
</template>

<script>
import { mapGetters } from 'vuex';
import utils from '@/utils';
import Page from '@/views/Page.vue';
import PageDetail from '@/components/pages/PageDetail.vue';
import HoraireItem from '@/components/HoraireItem.vue';

export default {
  components: {
    PageDetail,
    Page,
    HoraireItem,
  },
  data() {
    return {
      currentTab: 'apropos',
    };
  },
  methods: {
    nl2br(text) {
      return utils.nl2br(text);
    },
  },
  computed: {
    ...mapGetters('agenda', ['getEventById']),
    event() {
      const event = this.getEventById(this.$route.params.id);

      if (!event) {
        return false;
      }

      return event;
    },
  },
};
</script>
