<template>
  <PageMap
    :locations="spots"
    typeSelection="region/deux-heures"
    :maxZoom="false"
    :autoBounds="true"
    class="page-region"
  >
    <RegionBloc />
  </PageMap>
</template>

<script>
import { mapGetters } from 'vuex';
import PageMap from '@/components/pages/PageMap.vue';
import RegionBloc from '@/components/RegionBloc.vue';

export default {
  components: {
    PageMap,
    RegionBloc,
  },
  computed: {
    ...mapGetters('region', ['getItemsOfCurrentLang']),
    spots() {
      return this.getItemsOfCurrentLang('deux-heures');
    },
  },
};
</script>
