<template>
  <button class="selection-button" :class="{ selected: isSelected, big, 'with-text': withText  }" @click="toggleToSelection">
    <i class="icon" :class="isSelected ?
      'icon-heart' : 'icon-heart-outline'"></i>
    <span v-if="withText">{{$t(isSelected ? 'selection.remove' : 'selection.add')}}</span>
  </button>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  props: {
    type: String,
    item: Object,
    big: {
      type: Boolean,
      default: false,
    },
    withText: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters('selection', ['isInSelection']),
    isSelected() {
      return this.isInSelection(this.type, this.item.id);
    },
  },
  methods: {
    ...mapActions('selection', ['toggleItem']),
    toggleToSelection() {
      this.toggleItem({
        type: this.type,
        item: this.item,
        id: this.item.id,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.selection-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 57px;
  height: 57px;
  padding: 0;
  overflow: visible;
  transition: color $transition-duration ease, background-color $transition-duration ease;
  border: 2px solid $red;
  border-radius: 100px;
  background-color: $white;
  color: $red;
  cursor: pointer;

  &.big {
    width: 100px;
    height: 100px;
    border-width: 3px;

    .icon {
      font-size: 3.6rem;
    }
  }

  &.with-text {
    width: auto;
    height: 90px;
    padding: 5px 40px;
    font-size: 2.2rem;
    font-weight: $bold-weight;
    text-transform: uppercase;

    .icon {
      margin-right: 20px;
      font-size: 2.6rem;
    }
  }

  .icon {
    margin-top: 2px;
    font-size: 2.3rem;
  }

  &.selected {
    background-color: $red;
    color: $white;
  }
}
</style>
