<template>
  <div id="app" @click="onInteraction" @touchend="onInteraction" :class="{ a11y: isA11yActivate }">
    <div class="a11y-image">
      <div class="outro">
        <p class="hashtag">#VisitezToulouse</p>
        <p class="website">www.toulouse-tourisme.com</p>
      </div>
    </div>
    <div class="main-container">
      <keep-alive>
        <router-view @fully-loaded="handleViewLoad"/>
      </keep-alive>

      <SelectionModal ref="selectionModal"/>
      <ResetModal
        ref="resetModal"
        @back="resetApp"
        @cancel="onInteraction"
      />
    </div>

    <svg width="0" height="0" class="masks">
      <defs>
        <clipPath id="menuMask" clipPathUnits="objectBoundingBox">
          <path
            d="M0,0c0,0.025,0.045,0.045,0.5,0.062C0.955,0.079,1,0.128,1,0.154
            s0,0.644,0,0.685C1,0.88,1,0.925,0.5,0.942
            C0,0.96,0,0.984,0,1C0,1.015,0,0,0,0z"
          ></path>
        </clipPath>
        <clipPath id="menuHorizontalMask" clipPathUnits="objectBoundingBox">
          <path d="M0,1c0,0,0.071,0,0.073-0.527C0.073,0.027,0.107,0,0.15,0c0.043,0,0.655,0,0.696,0 c0.04,0,0.08-0.027,0.08,0.473C0.926,0.973,1,1,1,1L0,1z"/>
        </clipPath>
        <clipPath id="cardMask" clipPathUnits="objectBoundingBox">
          <path d="M1,0.722V1H0V0C0.292,1.352,1,0.722,1,0.722z"/>
        </clipPath>
        <clipPath id="cardVerticalMask" clipPathUnits="objectBoundingBox">
          <path d="M0,0h1v1H0c0,0,0.869-0.182,0.869-0.486S0,0,0,0z"/>
        </clipPath>
      </defs>
    </svg>

    <div class="loader" :class="{'js-updating': updating}">
      <div class="spinner"></div>
      <p class="loading">Mise à jour en cours, veuillez patienter</p>
    </div>

    <div class="loader" :class="{'js-updating': preloadingPages}">
      <div class="spinner"></div>
      <p class="loading">Préchargement des pages : {{pagesToLoadCount - loadableRoutes.length}} / {{pagesToLoadCount}}</p>
    </div>

    <StandBy v-if="standbyData && standbyData.disabled === true" :display="standbyData.affichage" />
  </div>
</template>

<script>
// @ is an alias to /src
import { mapActions, mapState, mapGetters } from 'vuex';
import SelectionModal from '@/components/modals/SelectionModal';
import ResetModal from '@/components/modals/ResetModal';
import StandBy from '@/components/StandBy';
import config from '@/config';
import utils from '@/utils';
import WordpressAjax from '@/wordpress-ajax';

export default {
  name: 'app',
  components: {
    SelectionModal,
    ResetModal,
    StandBy,
  },
  data() {
    return {
      delay: null,
      interval: null,
      updating: false,
      preloadingPages: false,
      loadableRoutes: [],
      pagesToLoadCount: 0,
      standbyData: false,
    };
  },
  computed: {
    ...mapState(['isA11yActivate']),
    ...mapGetters(['needToUpdate']),
  },
  methods: {
    ...mapActions(['updateAllData']),
    ...mapActions(['resetAppData']),
    showSelectionModal() {
      this.$refs.selectionModal.open();
    },
    showResetModal() {
      this.$refs.resetModal.open();
    },
    onInteraction() {
      this.resetTimer();
    },
    resetTimer() {
      if (this.interval) {
        clearInterval(this.interval);
      }

      if (this.$route.name === 'home' || !config.enableTimer) {
        return;
      }

      this.delay = config.popupShowDelay;

      if (this.interval) {
        clearInterval(this.interval);
      }

      this.interval = setInterval(() => {
        if (this.delay <= 0) {
          clearInterval(this.interval);
          this.showResetModal();
          return;
        }
        this.delay -= 1;
      }, 1000);
    },
    resetApp() {
      console.info('Reset app data');

      this.$router.push('/');
      this.resetAppData();
    },
    loadAllPages() {
      if (!config.enablePagesPreload) {
        return false;
      }

      const routes = this.$router.options.routes;
      this.loadableRoutes = [];
      this.$router.push('/').catch(() => {});

      routes.forEach((route) => {
        if (route.children) {
          route.children.forEach((childRoute) => {
            if (childRoute.component && childRoute.path.indexOf(':') === -1) {
              this.loadableRoutes.push(`${route.path}/${childRoute.path}`);
            }
          });
        }
      });

      this.pagesToLoadCount = this.loadableRoutes.length;
      this.preloadingPages = true;

      utils.wait(500)
        .then(() => {
          this.preloadNextRoute();
        });
      return true;
    },
    handleViewLoad() {
      if (!config.enablePagesPreload) {
        return;
      }

      this.preloadNextRoute();
    },
    preloadNextRoute() {
      if (this.loadableRoutes.length <= 0) {
        this.preloadingPages = false;
        this.$router.push('/').catch(() => {});
        return;
      }
      const nextRoute = this.loadableRoutes.pop();

      this.$router.push(nextRoute)
        .catch((error) => {
          console.error(error);
        });
    },
  },
  mounted() {
    const body = document.querySelector('body');

    Array.from(body.querySelectorAll('.scroll-area .inner-scroll-area')).forEach((scrollArea) => {
      scrollArea.onscroll = this.onInteraction;
    });

    window.onscroll = this.onInteraction;

    this.$root.$on('fully-loaded', this.handleViewLoad);

    this.resetTimer();
    this.updating = true;

    return WordpressAjax.get('get_gestion_tablettes')
      .then((res) => {
        if (res.disabled) {
          this.standbyData = res;
          return Promise.resolve()
            .then(() => {
              this.updating = false;
            });
        }

        return this.updateAllData()
          .catch((error) => {
            console.error('Update Error', error);
          })
          .then(() => {
            this.updating = false;
            this.loadAllPages();
          });
      });

    // if (this.needToUpdate) {
    //   this.updating = true;

    // }

    // return this.loadAllPages();
  },
};
</script>

<style lang="scss">
@import './scss/main.scss';

#app {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 2160px;
  height: 3840px;

  .main-container {
    position: relative;
    z-index: 1;
    flex: 1 1 auto;
    background: $gradient-toulouse;
  }

  .a11y-image {
    position: relative;
    flex: 1 0 auto;
    height: 0;
    transition: height $transition-duration ease;

    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      bottom: -30px;
      left: 0;
      background-image: url('~@/assets/images/toulouseV2-min.jpg');
      background-position: top;
      background-size: cover;
    }
  }

  &.a11y {
    .a11y-image {
      position: relative;
      height: $a11y-image-height;
    }

    .main-container {
      overflow: hidden;
      border-top-left-radius: 30px;
      border-top-right-radius: 30px;
    }

    .outro {
      position: absolute;
      z-index: 100;
      top: $a11y-image-height * 0.5;
      right: 500px;
      bottom: auto;
      left: 500px;
    }
  }

  .view {
    width: 100%;
    height: 100%;
  }

  .page-intro {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .logo {
      flex: 0 0 auto;
      width: 353px;
      margin-top: 20px;
    }
  }
}

.main-container {
  height: 100%;
}

.masks {
  display: block;
}

.loader {
  display: flex;
  position: fixed;
  z-index: 50;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 3rem 0;
  transition: opacity $transition-duration ease;
  opacity: 0;
  background-color: rgba($black, 0.9);
  color: $white;
  pointer-events: none;

  &.js-updating {
    opacity: 1;
    pointer-events: all;
  }

  .spinner {
    width: 50px;
    height: 50px;
    margin: $spacing-alt auto $spacing-small;
    animation: rotate 2s infinite linear;
    border: 5px solid $white;
    border-radius: 50%;
    border-top-color: rgba($white, 0.2);
  }

  @keyframes rotate {
    from { transform: rotate(0deg); }
    to { transform: rotate(360deg); }
  }
}
</style>
